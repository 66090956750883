import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import withMobileDialog from "@material-ui/core/withMobileDialog";
const domain = process.env.REACT_APP_BASE_PATH_FILES;
const serverUrl = process.env.REACT_APP_SERVER_URL_IMAGES;

const styles = theme => ({
  table: {
    borderCollapse: "collapse",
    margin: "0 auto",
    textAlign: "center",
    overflow: "auto"
  },
});

class TermsConditionPrivate extends Component {
  render() {
    const { classes } = this.props;

    return (
      <div className="max-w-5xl mx-auto w-full">
        <div>
          <p className="text-center my-4">
            <b>TÉRMINOS Y CONDICIONES DE USO PLAN ASISTENSI</b>
          </p>
        </div>
        <div className="px-4 text-jusitfy">
          <div>
            <p className="my-4">
            El presente contrato de servicios se suscribe entre{" "}
              <b>ASISTENSI GLOBAL INSURANCE, INC</b> en adelante denominado{" "}
              <b>“ASISTENSI”</b> y usted, en lo adelante denominado el{" "}
              <b>“TOMADOR DEL PLAN”</b>. Este contrato contiene los términos
              y condiciones de uso para los servicios de salud{" "}
              <b>PLAN ASISTENSI</b> o <b>PLAN ASISTENSI PLUS</b> en delante
              denominados <b>“PLAN ASISTENSI"</b>,<b>"PLAN"</b> o <b>"PLANES”</b>.
            </p>
          </div>
          <div>
            <p className="my-4">
              El uso del <b>PLAN ASISTENSI</b> está condicionado a
              la aceptación por parte del <b>TOMADOR DEL PLAN </b>de los términos y
              condiciones de uso descritos a continuación.
            </p>
          </div>
          <div>
            <p className="my-4">
              Lea atentamente el presente documento antes de contratar y utilizar este servicio de salud. Al acceder y utilizar este servicio de salud, Usted expresa su reconocimiento y aceptación de los siguientes términos y condiciones de uso y su consentimiento respecto a recibir los servicios ofrecidos.{" "}Si no está de acuerdo con estos términos y condiciones, no utilice los servicios referidos en el PLAN ASISTENSI o PLAN ASISTENSI PLUS.
            </p>
          </div>
          <div>
            <p className="my-4">
              Los presentes términos y condiciones de uso son de aplicación para
              todos los servicios de salud provistos a través del <b>PLAN
              ASISTENSI</b>.
            </p>
          </div>
          <div>
            <p className="my-4">
              <b>1. Definiciones y conceptos</b>
            </p>
            <div className="ml-5">
              <p className="my-4">
                <b>1.1 MERCANTIL SEGUROS: </b>Empresa de seguros inscrita y autorizada en la Superintendencia de la Actividad Aseguradora bajo el número 74 con el RIF: J-000901805 conforme a la Ley de la Actividad Aseguradora de 15 de marzo de 2016 publicada en la Gaceta Oficial Nº6.220, proveedor asegurador, que otorga y asume los riesgos cubiertos en el PLAN ASISTENSI y en el PLAN ASISTENSI PLUS de cobertura de salud para emergencias médicas en CENTROS DE SALUD adscritos a la red de CENTROS DE SALUD de MERCANTIL SEGUROS y que tengan convenio activo con la misma para los BENEFICIARIOS en la República Bolivariana de Venezuela mediante la PÓLIZA.
              </p>
              <p className="my-4">
                <b>1.2 ACCIDENTE: </b> Suceso violento, súbito, externo y ajeno a la intencionalidad del BENEFICIARIO, que le cause a este último lesiones corporales.
              </p>
              <p className="my-4">
                <b>1.3 ÁMBITO TERRITORIAL: </b> La provisión de los servicios se limita al territorio de la República Bolivariana de Venezuela, en las siguientes ciudades: Caracas, Gran Caracas, Barcelona, Barquisimeto, Cagua, Charallave, Guacara, Guarenas, Guatire, La Victoria, Lechería, Los Teques, Maracaibo, San Francisco, Maracay, Pampatar, Porlamar, Puerto La Cruz, Puerto Ordáz, San Antonio de Los Altos, San Cristóbal, Turmero, Valencia y Vargas.
              </p>
              <p className="my-4">
                <b>1.4. ASISTENCIA MÉDICA IN SITU: </b> Es la prestación del servicio de atención médica, que los médicos ofrecerán en el lugar donde se encuentre el BENEFICIARIO, mediante las siguientes modalidades de asistencia:
                <div className="my-2 ml-7">
                  <ul className="list-disc">
                    <li>ATENCIÓN MÉDICA DOMICILIARIA.</li>
                    <li>ORIENTACIÓN MÉDICA TELEFÓNICA.</li>
                    <li>ORIENTACIÓN MÉDICA VIRTUAL.</li>
                  </ul>
                </div>
              </p>
              <p className="my-4">
                <b>1.5 ASISTENSI: </b> Representado por Asistensi Global Insurance, Inc. empresa constituida debidamente según las Leyes de Barbados, que coordina la prestación de servicios con proveedores, subsidiarias y compañías afiliadas, comercializando el PLAN ASISTENSI y el PLAN ASISTENSI PLUS por sí misma o a través de las sociedades pertenecientes a su mismo grupo, incluyendo sus subsidiarias y compañías afiliadas.
              </p>
              <p className="my-4">
                <b>1.6. ATENCIÓN MÉDICA DOMICILIARIA: </b> Atención médica de carácter individual en el lugar donde se encuentre el BENEFICIARIO y dentro del ámbito territorial especificado en el presente documento.
              </p>
              <p className="my-4">
                <b>1.7. BENEFICIARIO: </b> persona natural, residente en la República Bolivariana de Venezuela, a través de la CONTRATACIÓN por parte del TOMADOR DEL PLAN y aceptación de los términos y condiciones de uso incluida en el PLAN.
              </p>
              <p className="my-4">
                <b>1.8. BUEN ESTADO DE SALUD: </b> Estado de bienestar o equilibrio en el que no se presente ninguna alteración de la salud que origine reducción de la capacidad funcional ni que pueda requerir tratamiento médico y/o intervención quirúrgica.

                <p className="my-2">De igual forma, incluye no padecer o haber padecido alguna/s de las siguientes enfermedades: </p>

                <div className="my-2 ml-7">

                  <ul className="list-disc my-2 space-y-3">
                    <li>Alteraciones de salud que originen reducción de la capacidad funcional moderada o severa, incluyendo personas postradas en la cama, Alzheimer, esquizofrenia o demencia.</li>

                    <li>Enfermedades cardiovasculares, incluyendo cardiopatía isquémica, angina de pecho, infarto al miocardio, insuficiencia cardíaca, insuficiencia valvular aórtica y otras válvulas cardíacas, Accidente Cerebrovascular (ACV), ataques de isquemia cerebral, aneurisma, angioplastia o colocación de stent, marcapasos.</li>

                    <li>Enfermedades autoinmunes, incluyendo EBPOC (Enfermedad Broncopulmonar Obstructiva Crónica), insuficiencia renal crónica, lupus, esclerosis múltiple, artritis reumatoide, esclerodermia.</li>

                    <li>Hipertensión arterial sin tratamiento médico y no controlada.</li>

                    <li>Diabetes mellitus sin tratamiento médico y no controlada, lo cual se entenderá en todo caso para aquellas personas con un nivel de hemoglobina glicosilada superior o igual al 7%. Las personas que padezcan diabetes mellitus y no puedan demostrar que su nivel de hemoglobina glicosilada se encuentra en un nivel igual o inferior al 7% con un análisis clínico de un laboratorio médico reconocido de menos de 90 días desde su medición y enviado a ASISTENSI en los primeros quince (15) días desde la contratación del PLAN se considerarán que no gozan de BUEN ESTADO DE SALUD.</li>

                    <li>Cáncer.</li>

                  </ul>
                </div>
              </p>
              <p className="my-4">
                <b>1.9. CENTRAL DE ALARMA TELEFÓNICA: </b> Unidad de atención que funciona las veinticuatro (24) horas del día durante todo el año, para coordinar la prestación eficiente del servicio, centralizando y validando las llamadas telefónicas. Los números de contacto son Venezuela: +58 (212) 822 12 60 y +58 (800) 836.36.37; España: +34 (911) 238 276; Estados Unidos: +1 (305) 455 88 11.
              </p>
              <p className="my-4">
                <b>1.10. CENTRO DE SALUD: </b> institución hospitalaria, clínica u hospital privado para el cuidado de la salud de pacientes.
              </p>
              <p className="my-4">
                <b>1.11 COBERTURA DE SALUD PARA EMERGENCIAS MÉDICAS EN CENTROS DE SALUD: </b> Cobertura de gastos médicos prevista en la PÓLIZA de un importe máximo de DIEZ MIL DÓLARES AMERICANOS (USD. 10.000), dependiendo de si el BENEFICIARIO contrata el PLAN ASISTENSI o el PLAN ASISTENSI PLUS, pagaderos en Bolívares Digitales al CENTRO DE SALUD, calculados con base en el FACTOR DE TASA DE CAMBIO calculado por MERCANTIL SEGUROS.
              </p>
              <p className="my-4">
                <b>1.12. CONSULTA: </b> Orientación médica que solicita el BENEFICIARIO.
              </p>
              <p className="my-4">
                <b>1.13. CONTRATACIÓN: </b> Procedimiento a llevar a cabo por el TOMADOR DEL PLAN para la inclusión del BENEFICIARIO o BENEFICIARIOS en los PLANES ASISTENSI. Respecto a la contratación realizada por el TOMADOR DEL PLAN, cada uno de los Beneficiarios sólo podrán acceder a un sólo Plan. En ningún caso se podrán contratar dos (2) planes a la vez para los BENEFICIARIOS ya sea por el mismo TOMADOR DEL PLAN o por distintos TOMADORES DEL PLAN.
              </p>
              <p className="my-4">
                <b>1.14. EMERGENCIA MÉDICA: </b> Condición de carácter súbito y repentino, manifestada por síntomas agudos y severos que requieren de una atención inmediata, y que en caso de no recibir la atención médica necesaria y oportuna pueda poner en peligro la vida o la función de un órgano del BENEFICIARIO. Se entienden también comprendidas en el presente término aquellas situaciones de urgencia que no pongan en evidente peligro la vida ni ocasionen consecuencias graves para las personas, por lo que no amerita atención médica de manera súbita.
              </p>
              <p className="my-4">
                <b>1.15. ENFERMEDAD: </b> Alteración de la salud que resulte de la acción de agentes morbosos de origen interno o externo con relación al organismo, que origine reducción de su capacidad funcional y que requiera tratamiento médico y/o intervención quirúrgica.
              </p>
              <p className="my-4">
                <b>1.16. ENFERMEDAD CONGÉNITA: </b>Se considera enfermedad congénita a todas aquellas condiciones, lesiones o enfermedades que hayan sido manifestadas y reconocidas al momento de su nacimiento, o bien hayan sido descubiertas durante el transcurso de su vida, como consecuencia de factores hereditarios o adquiridos durante la gestación hasta el momento del parto.
              </p>
              <p className="my-4">
                <b>1.17. ENFERMEDAD PREEXISTENTE: </b> Enfermedad o lesión que pueda comprobarse ha sido adquirida
                con anterioridad a la fecha de inicio de la vigencia de la Póliza, y sea conocida por el Asegurador
                o el BENEFICIARIO.
              </p>
              <p className="my-4">
                <b>1.18. ENTREGA DE MEDICAMENTOS: </b>Servicios de entrega de INVENTARIO DOMICILIARIO y de INVENTARIO CRÍTICO.
              </p>
              <p className="my-4">
                <b>1.19. EQUIPO DE SALUD: </b>Conjunto de profesionales médicos y paramédicos debidamente certificados y autorizados para el ejercicio profesional amparado en la Ley del Ejercicio de la Medicina, calificados en la atención de EMERGENCIAS MÉDICAS de tipo prehospitalarias.
              </p>
              <p className="my-4">
                <b>1.20. FACTOR DE TASA DE CAMBIO: </b>Resultado de la fórmula utilizada para calcular el tipo de cambio que se aplicará al monto de la cobertura a pagar al CENTRO DE SALUD. El resultado dependerá del valor de la tasa oficial de cambio de divisas de la República Bolivariana de Venezuela y el promedio de cambio utilizado por el sistema de CENTROS DE SALUD. MERCANTIL SEGUROS se reserva el derecho de establecer dicho factor como resultado de sus cálculos propios.
              </p>
              <p className="my-4">
                <b>1.21. HORARIO DE SERVICIO: </b>Es el que se establece a continuación y variará en función de la modalidad del servicio:

                <div className="my-2 ml-7">
                  <ul className="my-2 space-y-3">
                    <li>
                      <b>1.21.1. Orientación Médica Telefónica, Atención Médica Domiciliaria, Traslados, Cobertura de Salud para Emergencias Médicas en Centros de salud: </b> las veinticuatro (24) horas del día y los trescientos sesenta y cinco (365) días del año de conformidad con lo dispuesto en el presente documento.
                    </li>

                    <li>
                      <b>1.21.2. Orientación Médica Virtual: </b> de 7:30am a 11:00pm (GMT -4), en horario de la República Bolivariana de Venezuela, los trescientos sesenta y cinco (365) días del año.
                    </li>

                    <li>
                      <b>1.21.3. Pruebas De Laboratorios Clínicos: </b>
                      En función del horario de atención del proveedor local elegido por el BENEFICIARIO para la prestación del servicio.</li>
                  </ul>
                </div>
              </p>
              <p className="my-4">
                <b>1.22. INVENTARIO CRÍTICO: </b>Listado de medicamentos, insumos, materiales y equipos médicos disponibles que pueden ser requeridos por un BENEFICIARIO en el ámbito del ingreso/hospitalización en un CENTRO DE SALUD durante las primeras setenta y dos (72) horas de una PATOLOGÍA / EVENTO que ponga en riesgo la salud y/o vida.
              </p>
              <p className="my-4">
                <b>1.23. INVENTARIO DOMICILIARIO: </b>Listado de medicamentos e insumos médicos disponibles que pueden ser requeridos por un BENEFICIARIO como consecuencia de una PATOLOGÍA / EVENTO diagnosticado por un médico profesional en el ámbito de una consulta médica privada o ATENCIÓN MÉDICA IN SITU.
              </p>
              <p className="my-4">
                <b>1.24. MEMBRESÍA ASISTENSI: </b>Es la condición de miembro de una comunidad de usuarios registrados en las plataformas digitales de ASISTENSI para tener acceso exclusivo a diversos beneficios y, en determinados casos, para la contratación de planes y servicios exclusivos.
              </p>
              <p className="my-4">
                <b>1.25. ORIENTACIÓN MÉDICA TELEFÓNICA: </b>Son aquellas recomendaciones médicas telefónicas al BENEFICIARIO, al comunicarse con la CENTRAL DE ALARMA TELEFÓNICA. El alcance de las recomendaciones dependerá de la condición (patología) indicada por el BENEFICIARIO, en los cuales no se requiere de una UNIDAD MÉDICA.
              </p>
              <p className="my-4">
                <b>1.26. ORIENTACIÓN MÉDICA VIRTUAL: </b>Asesoramiento a distancia a través de videoconferencia, o mediante acceso disponible a través de la aplicación (App) móvil de ASISTENSI. El alcance de la asesoría dependerá de la condición (patología) indicada por el BENEFICIARIO, en los cuales no se requiere de una UNIDAD MÉDICA.
              </p>
              <p className="my-4">
                <b>1.27. PATOLOGÍA / EVENTO: </b>Accidente, enfermedad, emergencia médica que comprometan la salud y/o vida del BENEFICIARIO.
              </p>
              <p className="my-4">
                <b>1.28. PERSONAL MÉDICO Y TÉCNICO: </b>Profesionales médicos debidamente autorizados por la Ley del Ejercicio de la Medicina, paramédicos y personal de soporte calificado para la atención médica de EMERGENCIAS MÉDICAS; así como los conductores de las UNIDADES MÉDICAS. Ninguno de los profesionales médicos antes mencionados, será considerado, en cualquier caso, como médico tratante.
              </p>
              <p className="my-4">
                <b>1.29. PLAN ASISTENSI: </b>Servicio de salud orientado a la atención del BENEFICIARIO ante una EMERGENCIA MÉDICA a través de la prestación de servicios TRASLADOS, ASISTENCIA MÉDICA IN SITU, PRUEBAS DE LABORATORIOS CLÍNICOS , ENTREGA DE MEDICAMENTOS y, en caso de que haya sido contratada, la inclusión del BENEFICIARIO en la PÓLIZA para obtener una COBERTURA DE SALUD PARA EMERGENCIAS MÉDICAS EN CENTROS DE SALUD en la República Bolivariana de Venezuela. Dentro del PLAN ASISTENSI, existen dos modalidades con diferentes coberturas: PLAN ASISTENSI y PLAN ASISTENSI PLUS.
              </p>
              <p className="my-4">
                <b>1.30. PÓLIZA: </b>Incluye la COBERTURA DE SALUD PARA EMERGENCIAS MÉDICAS EN CENTROS DE SALUD contratada por ASISTENSI a favor de los BENEFICIARIOS, cuyas condiciones generales son descargables mediante este{' '}
                <a
                  href={`${serverUrl}/documents/VEN+-+T%26Cs+-+Enlace+Cl+1.30+-+Poliza_Mercantil_Global_Benefits+-+v.22.03.2022.pdf`}
                  className="ml-1 cursor-pointer text-purple-500 font-bold underline"
                  target="_blank"
                >
                  enlace
                </a> y cuyas condiciones particulares se adjuntan a los presentes TÉRMINOS Y CONDICIONES como <b>Anexo 1.30</b>.
              </p>
              <p className="my-4">
                <b>1.31. PRUEBAS DE LABORATORIOS CLÍNICOS: </b>Servicio ofrecido al BENEFICIARIO para que acuda a realizarse un análisis o prueba clínica. El BENEFICIARIO tendrá derecho a dos (2) análisis por año cualquiera que sea la modalidad de PLAN contratado.
              </p>
              <p className="my-4">
                <b>1.32. SISTEMA COORDINADO DE PROVEEDORES: </b>Empresas, médicos, paramédicos, enfermeras y CENTROS DE SALUD que prestarán los servicios de asistencia sanitaria previstos en esta PÓLIZA. Su composición variará en función de los acuerdos que en cada momento MERCANTIL SEGUROS, por sí mismo o a través de terceros, celebre con los proveedores legalmente autorizados para prestar los servicios de asistencia sanitaria previstos en esta PÓLIZA.
              </p>
              <p className="my-4">
                <b>1.33. TÉRMINOS Y CONDICIONES: </b>Significa los presentes TÉRMINOS Y CONDICIONES.
              </p>
              <p className="my-4">
                <b>1.34. TOMADOR DEL PLAN: </b>Persona física o jurídica no residente en la República Bolivariana de Venezuela (para cualesquiera otros supuestos serán de aplicación los términos y condiciones del{' '}
                  <a
                  href={`${serverUrl}/documents/VEN-T%26Cs-Contrato-de-afiliacion-y-contratos-adicionales.pdf`}
                  className="ml-1 cursor-pointer text-purple-500 font-bold underline"
                  target="_blank"
                > enlace
                </a>) que contrata los servicios de ASISTENSI para el BENEFICIARIO. El TOMADOR DEL PLAN será el responsable de la inclusión de los BENEFICIARIOS en el PLAN o PLANES, del pago correspondiente por la CONTRATACIÓN del PLAN o PLANES y del cumplimiento de los TÉRMINOS Y CONDICIONES.
              </p>
              <p className="my-4">
                <b>1.35. TRASLADOS: </b>Servicio del PLAN ASISTENSI que incluye las siguientes modalidades
                <div className="my-2 ml-7">
                  <ul className="list-disc my-2 space-y-3">
                    <li>TRASLADO DE RETORNO o ALTA MÉDICA;</li>
                    <li>TRASLADO DE TERAPIA INTENSIVA;</li>
                    <li>TRASLADO A OTRA INSTITUCIÓN HOSPITALARIA o INTERINSTITUCIONAL;</li>
                    <li>TRASLADO EN AMBULANCIAS; y</li>
                    <li>TRASLADO PROGRAMADO.</li>
                  </ul>
                </div>
              </p>
              <p className="my-4">
                <b>1.36. TRASLADO A OTRA INSTITUCIÓN HOSPITALARIA o INTERINSTITUCIONAL (urbano): </b>Después que el BENEFICIARIO haya ingresado a un CENTRO DE SALUD como consecuencia de una emergencia, este podrá solicitar, con al menos doce (12) horas de antelación una UNIDAD MÉDICA de traslado a otro CENTRO DE SALUD dentro de la misma ciudad/población donde se encuentra el CENTRO DE SALUD inicial si el personal médico lo determina necesario.
              </p>
              <p className="my-4">
                <b>1.37. TRASLADO DE RETORNO o ALTA MÉDICA (urbano): </b>Después que el BENEFICIARIO haya recibido el alta médica en el CENTRO DE SALUD, éste podrá solicitar con al menos doce (12) horas de antelación, una UNIDAD MÉDICA de traslado de retorno a su domicilio dentro de la misma ciudad/población de ocurrencia del hecho si el personal médico lo determina necesario.
              </p>
              <p className="my-4">
                <b>1.38. TRASLADO DE TERAPIA INTENSIVA: </b>Coordinación y movilización de personal médico y técnico y de una UNIDAD MÉDICA especializado para la atención de soporte de vida avanzado (terapia intensiva) y con equipos de monitorización de signos vitales, desfibrilación, aspiración, ventilación mecánica y/o incubadora, para la prestación del servicio en la atención de pacientes que requieren ser movilizados entre dos servicios distintos de terapia intensiva de CENTROS DE SALUD, en los cuales se requiera realizar el traslado del BENEFICIARIO, siempre que se trate de beneficiarios adultos, pediátricos y/o neonatales, en un horario determinado y/o con características especiales a un destino predeterminado si el personal médico lo determina necesario. Deberá ser solicitado con al menos doce (12) horas de antelación al momento del TRASLADO DE TERAPIA INTENSIVA.
              </p>
              <p className="my-4">
                <b>1.39. TRASLADO EN AMBULANCIAS: </b>Coordinación y movilización de personal médico y técnico y de una UNIDAD MÉDICA, para la prestación del servicio en la atención de accidentes, enfermedad o emergencias en los cuales se requiera realizar el traslado de la persona a un CENTRO DE SALUD dentro de los límites de una ciudad o población (desde-hasta) de manera inmediata.
              </p>
              <p className="my-4">
                <b>1.40. TRASLADO PROGRAMADO: </b>Coordinación y movilización de personal médico y técnico y de una UNIDAD MÉDICA, para la prestación del servicio en la atención de accidentes, enfermedad en los cuales se requiera realizar el traslado de la persona en un horario determinado y/o con características especiales a un destino predeterminado si el personal médico lo determina necesario.
              </p>
              <p className="my-4">
                <b>1.41. UNIDAD MÉDICA: </b>Vehículos dotados con equipos destinados a la atención de EMERGENCIAS MÉDICAS, acondicionadas con insumos e instrumentos para la estabilización de la condición física que presente el BENEFICIARIO, que pueden ser de tres (3) tipos:
                <div className="my-2 ml-7">
                  <ul className="my-2 space-y-3">
                    <li>
                      <b>1.41.1. Ambulancia: </b>Vehículo de transporte destinado para la atención de EMERGENCIA MÉDICA en las que exista peligro inminente para la vida del paciente, equipadas con todo el soporte necesario para prestar este tipo de atención y su tripulación consta de personal paramédico, médico (opcional) y conductor.
                    </li>
                    <li>
                      <b>1.41.2. Avance: </b>Vehículo de transporte con dotación médica para atender emergencia no grave, que no ameriten traslado del paciente y su tripulación consta de personal paramédico-conductor y médico.
                    </li>
                    <li>
                      <b>1.41.3. Moto-Ambulancia: </b>Motocicleta adaptada con sistemas de identificación, luces, sirena y equipamiento de emergencia para el traslado de personal paramédico y médico a los fines de realizar la atención de EMERGENCIA MÉDICA de carácter no grave. En caso de requerir eventual traslado del BENEFICIARIO a una institución prestadora de servicios de salud escogida por éste, deberá ser realizada por una UNIDAD MÉDICA, de tipo Ambulancia o Avance.
                    </li>
                  </ul>
                </div>
              </p>
              <p className="my-4">
                <b>1.42. UNIDOSIS: </b>Dosis concreta y unitaria de un medicamento que un BENEFICIARIO recibe en el momento de la evaluación médica para mejorar, tratar o eliminar un síntoma determinado.
              </p>
            </div>
          </div>
          <div>
            <p className="mt-6 my-4">
              <b>2. Objeto del contrato</b>
            </p>
            <div className="ml-3">
              <p className="my-4">
                ASISTENSI se obliga a poner a disposición de los BENEFICIARIOS, ya sea directamente o a través de terceros, un SISTEMA COORDINADO DE PROVEEDORES de amplia experiencia y 
                trayectoria, certificados bajo las leyes de la República Bolivariana de Venezuela, a los fines de la prestación de servicios primarios a través de ASISTENCIA MÉDICA IN 
                SITU, PRUEBAS DE LABORATORIOS CLÍNICOS, ENTREGA DE MEDICAMENTOS y TRASLADOS en el territorio de la República Bolivariana de Venezuela. De igual manera, los BENEFICIARIOS 
                serán incluidos, en su caso, en la PÓLIZA para recibir la COBERTURA DE SALUD PARA EMERGENCIAS MÉDICAS EN CENTROS DE SALUD contratada por ASISTENSI a favor de los BENEFICIARIOS con MERCANTIL 
                SEGUROS siempre y cuando el BENEFICIARIO goce de BUEN ESTADO DE SALUD; todo de conformidad con el alcance y límites dispuestos en los presentes TÉRMINOS Y CONDICIONES.
              </p>
            </div>
          </div>
          <div>
            <p className="mt-6 my-3">
              <b>3. Alcance de los servicios</b>
            </p>
            <div className="ml-3">
              <p className="my-4">
                <b>3.1. ASISTENCIA MÉDICA IN SITU: </b>
                Es la prestación del servicio de atención médica, que los médicos ofrecerán en el lugar donde se encuentre el BENEFICIARIO, mediante las siguientes modalidades de atención:
                <div className="my-2 ml-7">
                  <ul className="my-2 space-y-3">
                    <li>
                      <b>3.1.1. ORIENTACIÓN MÉDICA TELEFÓNICA: </b>Atención y orientación médica telefónica como primer contacto con ASISTENSI y ASISTENSI realizará la coordinación del servicio, en los cuales no se requiere de una UNIDAD MÉDICA. Puede ser un medio alternativo para la prestación del beneficio de ORIENTACIÓN MÉDICA VIRTUAL si el BENEFICIARIO contactó a ASISTENSI por teléfono. Se pueden enviar récipes e indicaciones por esta vía.
                    </li>
                    <li>
                      <b>3.1.2. ORIENTACIÓN MÉDICA VIRTUAL: </b>El equipo de salud de ASISTENSI a través de videoconferencia, realizará una clasificación que va a determinar una toma de decisiones en cuanto a la asistencia del BENEFICIARIO, basándose en función de los síntomas y manifestaciones subjetivas del BENEFICIARIO o acompañante, en aras de priorizar la atención médica.
                    </li>
                    <li>
                      <b>3.1.3. ATENCIÓN MÉDICA DOMICILIARIA: </b>El equipo de salud de ASISTENSI realizará una clasificación que va a determinar una toma de decisiones en cuanto a la asistencia del BENEFICIARIO, basándose en función de los síntomas y manifestaciones subjetivas del BENEFICIARIO o acompañante, en aras de priorizar la atención médica, enviando un médico y paramédico al sitio en donde se encuentra el BENEFICIARIO para la realización de una consulta médica que puede derivar en el diagnóstico y tratamiento en el sitio, aplicando tratamiento farmacológico UNIDOSIS y/o solicitando un TRASLADO EN AMBULANCIA para la derivación del caso a un nivel superior de prestación de servicios de salud. La ATENCIÓN MÉDICA DOMICILIARIA es un servicio de atención que excluye procedimientos tales como: sutura, nebulizaciones, hidratación, cambios de sondas vesicales, curas periódicas de heridas.
                    </li>
                    <li>
                      <b>3.1.4. Sistema de clasificación y prioridad: </b>
                      <div className="ml-7 my-3">
                        <ul className="my-2 space-y-3">
                          <li>
                            3.1.4.1.ORIENTACIÓN MÉDICA TELEFÓNICA y ORIENTACIÓN MÉDICA VIRTUAL <i>(Código Blanco)</i>: se trata de la orientación médica, dando un soporte básico al BENEFICIARIO ante determinadas patologías.
                          </li>
                          <li>
                            3.1.4.2.Atención médica no urgente <i>(Código Verde)</i>: se trata de una asistencia primaria para atender patologías comunes y/o enfermedades crónicas que no revistan peligro inminente para el BENEFICIARIO.
                          </li>
                          <li>
                            3.1.4.3.EMERGENCIA MÉDICA <i>(Código Amarillo)</i>: si el médico lo considera y lo solicita, tendrá el respaldo de una UNIDAD MÉDICA, o del medio de transporte que considere mejor para el traslado del BENEFICIARIO a un CENTRO DE SALUD que será especificado por él mismo o su acompañante, garantizando el ingreso del BENEFICIARIO.
                          </li>
                          <li>
                            3.1.4.4.EMERGENCIA MÉDICA <i>(Código Rojo)</i>: cuando se reciba a través de ORIENTACIÓN MÉDICA VIRTUAL un caso de EMERGENCIA MÉDICA se activará el procedimiento para su atención a través del personal médico y técnico, dándose prioridad a la solicitud. Se enviará el personal médico simultáneamente con una UNIDAD MÉDICA hasta el lugar de ocurrencia. Luego de la estabilización del BENEFICIARIO, éste será trasladado al CENTRO DE SALUD, pudiendo esta ser especificada por el BENEFICIARIO o su acompañante teniendo en cuenta la red de prestadores de servicios de MERCANTIL SEGUROS, garantizando el ingreso del BENEFICIARIO para aquellos casos que la EMERGENCIA MÉDICA tenga cobertura bajo la PÓLIZA de COBERTURA DE SALUD PARA EMERGENCIAS MÉDICAS EN CENTROS DE SALUD de MERCANTIL SEGUROS.
                          </li>
                        </ul>
                      </div>

                    </li>
                  </ul>
                </div>
              </p>
            </div>
            <div className="ml-3">
              <p className="my-4">
                <b>3.2. ENTREGA DE MEDICAMENTOS </b>
                <div className="my-2 ml-7">
                  <ul className="my-2 space-y-5">
                    <li>
                      <b>3.2.1. INVENTARIO DOMICILIARIO: </b>Entrega de medicamentos para la administración vía oral, inhalados, gotas y tópicos que sean indicados en el récipe médico 
                      entregado por el médico domiciliario de ASISTENSI y/o un médico calificado de un CENTRO DE SALUD. Siempre y cuando haya sido activado el servicio de salud personal 
                      por parte del BENEFICIARIO, éste podrá solicitar los medicamentos indicados por el médico en el ámbito de la ASISTENCIA MÉDICA IN SITU y/o consulta privada. El personal 
                      de ASISTENSI procederá a la entrega de los medicamentos en el domicilio del BENEFICIARIO en un período no mayor a seis (6) horas para Caracas y no mayor a doce (12) horas en el resto del ÁMBITO TERRITORIAL.

                        <div className="ml-7 my-3">
                          <ul className="my-2 space-y-3">
                            <li>
                            3.2.1.1.  <b>Límites: </b> El límite de cantidades de insumo es suficiente para cubrir hasta un máximo de siete (7) días continuos de tratamiento, por PATOLOGÍA/EVENTO, cubriendo 
                            ASISTENSI el importe total del medicamento. Cualquier dosis adicional requerida deberá ser pagada por el BENEFICIARIO y no generará reembolso para el BENEFICIARIO. 
                            </li>
                            <li>
                            3.2.1.2.  <b>Solicitud de cobertura: </b> el BENEFICIARIO deberá entregar informe o receta médica a su nombre a través del correo electrónico o la aplicación (App) móvil de ASISTENSI, 
                            debidamente suscritos por un médico colegiado, fechados y presentados a ASISTENSI dentro de las cuarenta y ocho (48) horas siguientes a su emisión. ASISTENSI podrá realizar el análisis 
                            de cada una de las solicitudes para asegurar que exista relación entre el diagnóstico y tratamiento indicado, así como para validar que las dosis indicadas del medicamento sean acordes 
                            al esquema regular de tratamiento indicado por patología y medicamento de acuerdo con los estándares internacionales. Una vez realizada la solicitud, se podrá realizar modificaciones a 
                            la misma, las cuales serán notificadas al usuario de forma pertinente..
                            </li>
                            <li>
                            3.2.1.3.  <b>Inventario incluido: </b> El listado de medicamentos incluidos es el que se especifica en el <b>Anexo 3.2.1.3</b>.
                            </li>
                          </ul>
                        </div>

                    </li>
                    <li>
                      <b>3.2.2. INVENTARIO CRÍTICO (hospitalario/clínico): </b>entrega de los requerimientos indispensables para asegurar la atención eficaz y oportuna de los pacientes hasta por las 
                      primeras setenta y dos (72) horas, consideradas como periodo crítico de actuación, con respecto a las patologías listadas en el <b>Anexo 3.2.2</b>, en el ámbito del ingreso a un CENTRO 
                      DE SALUD profesional.

                        <div className="ml-7 my-3">
                          <ul className="my-2 space-y-3">
                            <li>
                            3.2.2.1. <b>Límites: </b> El límite de cantidades de insumo es suficiente para cubrir hasta un máximo de setenta y dos (72) horas de tratamiento en CENTROS DE SALUD, por 
                            PATOLOGÍA/EVENTO, cubriendo ASISTENSI el importe total del medicamento. Cualquier dosis adicional requerida por fuera del límite de tiempo de cobertura deberá ser 
                            pagada por el BENEFICIARIO y no será reembolsable.
                            </li>
                            <li>
                            3.2.2.2.  <b>Solicitud de cobertura: </b> el BENEFICIARIO, hospitalizado en un CENTRO DE SALUD por EMERGENCIA MÉDICA, deberá entregar informe o receta médica a su nombre a 
                            través del correo electrónico o la aplicación (App) móvil de ASISTENSI.
                            </li>
                            <li>
                            3.2.2.3.  <b>Inventario incluido: </b>El listado de inventario crítico para uso hospitalario/clínico de material médico-quirúrgico, medicamentos e insumos y materiales específicos, incluido es el que se especifica en el <b>Anexo 3.2.2.3.</b>
                            </li>
                          </ul>
                        </div>
                    </li>
                  </ul>
                </div>
              </p>
            </div>
            <div className="ml-3">
              <p className="my-4">
                <b>3.3. PRUEBAS DE LABORATORIO CLÍNICO: </b>
                <p className="my-4">
                  Son un tipo de exploración confirmatoria solicitada por un médico al laboratorio clínico para confirmar o descartar un 
                  diagnóstico. Forma parte del proceso de atención médica al BENEFICIARIO y se apoya en el estudio de distintas muestras biológicas mediante su análisis en laboratorio 
                  brindando un resultado objetivo que puede ser cuantitativo o cualitativo. Dichas pruebas se realizarán con el proveedore que elija el BENEFICIARIO, procediendo ASISTENSI 
                  al reembolso del importe incurrido por el BENEFICIARIO o TOMADOR a tal fin.
                </p>
                  <div className="my-2 ml-7">
                    <ul className="my-2 space-y-5">
                      <li>
                        <b>3.3.1. LÍMITES: </b> el BENEFICIARIO tendrá derecho a dos (2) pruebas de laboratorio por año independientemente de la modalidad del PLAN, siempre y cuando se tenga 
                          receta o indicación médica previa y deberán pedirse directamente a ASISTENSI a través de la App o de la CENTRAL DE ALARMA TELEFÓNICA.
                      </li>
                      <li>
                        <b>3.3.2. AUTORIZACIÓN PREVIA OBLIGATORIA: </b> únicamente las pruebas previamente autorizadas por ASISTENSI serán cubiertas por el PLAN. No se cubrirán pruebas realizadas con anterioridad a la autorización de ASISTENSI.
                      </li>
                      <li>
                        <b>3.3.3. PRUEBAS INCLUIDAS: </b>las PRUEBAS DE LABORATORIO CLÍNICO a las que tiene acceso el BENEFICIARIO, y los estudios que incluye cada uno, son única y exclusivamente perfil infección urinaria, perfil infección básico, perfil infección completo y perfil infección enteral:
                      </li>
                    </ul>
                  </div>
              </p>
              <div className="my-2 ml-0 text-center w-full overflow-x-scroll sm:overflow-x-auto">
                  <table className="border-2 border-gray-800 w-full">
                    <thead className="border-2 border-gray-800 ">
                      <tr>
                        <th colSpan="2" className="text-center text-pink-500">PERFIL INFECCIÓN URINARIA</th>
                      </tr>
                      <tr className="bg-purple-500 border-2 border-gray-800">
                        <th className=" text-white text-center">Nombre Procedimiento</th>
                        <th className=" text-white text-center">Descripción Común</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr> <td>HEMOGRAMA</td> <td>HEMOGRAMA</td> </tr>
                      <tr> <td>URIANALISIS</td> <td>URIANALISIS</td> </tr>
                      <tr> <td>CREATININA</td> <td>CREATININA</td> </tr>
                      <tr> <td>BUN</td> <td>BUN</td> </tr>
                      <tr> <td>CULTIVO DE ORINA</td> <td>CULTIVO DE ORINA</td> </tr>
                      <tr> <td>ERITROSEDIMENTACION</td> <td>ERITROSEDIMENTACION</td> </tr>
                    </tbody>
                  </table>
                </div>
                <div className="mt-10 my-2 ml-0 text-center w-full overflow-x-scroll sm:overflow-x-auto">
                  <table className="border-2 border-gray-800 w-full">
                    <thead className="border-2 border-gray-800 ">
                      <tr>
                        <th colSpan="2" className="text-center text-pink-500">PERFIL INFECCIÓN BÁSICO</th>
                      </tr>
                      <tr className="bg-purple-500  border-2 border-gray-800">
                        <th className=" text-white text-center">Nombre Procedimiento</th>
                        <th className=" text-white text-center">Descripción Común</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr> <td>HEMOGRAMA</td> <td>HEMOGRAMA</td> </tr>
                      <tr> <td>GLUCOSA</td> <td>GLUCOSA/GLICEMIA</td> </tr>
                      <tr> <td>CREATININA</td> <td>CREATININA</td> </tr>
                      <tr> <td>BUN</td> <td>BUN</td> </tr>
                      <tr> <td>ERITROSEDIMENTACION</td> <td>ERITROSEDIMENTACION</td> </tr>
                    </tbody>
                  </table>
                </div>
                <div className="mt-10 my-2 ml-0 text-center w-full overflow-x-scroll sm:overflow-x-auto">
                  <table className="border-2 border-gray-800 w-full">
                    <thead className="border-2 border-gray-800 ">
                      <tr>
                        <th colSpan="2" className="text-center text-pink-500">PERFIL INFECCIÓN ENTERAL</th>
                      </tr>
                      <tr className="bg-purple-500 border-2 border-gray-800">
                        <th className=" text-white text-center">Nombre Procedimiento</th>
                        <th className=" text-white text-center">Descripción Común</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr> <td>HEMOGRAMA</td> <td>HEMOGRAMA</td> </tr>
                      <tr> <td>CREATININA</td> <td>CREATININA</td> </tr>
                      <tr> <td>BUN</td> <td>BUN</td> </tr>
                      <tr> <td>COPROLOGICO</td> <td>COPROLOGICO</td> </tr>
                      <tr> <td>ERITROSEDIMENTACION</td> <td>ERITROSEDIMENTACION</td> </tr>
                    </tbody>
                  </table>
                </div>
                <div className="mt-10 my-2 ml-0 text-center w-full overflow-x-scroll sm:overflow-x-auto">
                  <table className="border-2 border-gray-800 w-full">
                    <thead className="border-2 border-gray-800 ">
                      <tr>
                        <th colSpan="2" className="text-center text-pink-500">PERFIL INFECCIÓN COMPLETO</th>
                      </tr>
                      <tr className="bg-purple-500 border-2 border-gray-800">
                        <th className=" text-white text-center">Nombre Procedimiento</th>
                        <th className=" text-white text-center">Descripción Común</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr> <td>HEMOGRAMA</td> <td>HEMOGRAMA</td> </tr>
                      <tr> <td>BUN</td> <td>BUN</td> </tr>
                      <tr> <td>SGPT</td> <td>SGPT</td> </tr>
                      <tr> <td>SGOT</td> <td>SGOT</td> </tr>
                      <tr> <td>PCR</td> <td>PCR</td> </tr>
                      <tr> <td>GLUCOSA</td> <td>GLUCOSA/GLICEMIA</td> </tr>
                      <tr> <td>CREATININA</td> <td>CREATININA</td> </tr>
                    </tbody>
                  </table>
                </div>
            </div>
            <div className="ml-3">
              <p className="my-4">
                <b>3.4. TRASLADOS: </b>incluye las siguientes modalidades.
                  <div className="my-2 ml-7">
                    <ul className="my-2 space-y-3 list-disc">
                      <li>TRASLADO DE RETORNO o ALTA MÉDICA;</li>
                      <li>TRASLADO DE TERAPIA INTENSIVA;</li>
                      <li>TRASLADO A OTRA INSTITUCIÓN HOSPITALARIA o INTERINSTITUCIONAL;</li>
                      <li>TRASLADO EN ; y</li>
                      <li>TRASLADO PROGRAMADO.</li>
                    </ul>
                  </div>
              </p>
            </div>
            <div className="ml-3">
              <p className="my-4">
                <b>3.5. COBERTURA DE SALUD PARA EMERGENCIAS MÉDICAS EN CENTROS DE SALUD: </b>Cubre aquellos gastos médicos cubiertos por MERCANTIL SEGUROS incluidos en la PÓLIZA que tengan 
                lugar durante la vigencia del contrato por una EMERGENCIA MÉDICA en el territorio de la República Bolivariana de Venezuela que amerite la atención del BENEFICIARIO en un 
                CENTRO DE SALUD adscrito a la red de MERCANTIL SEGUROS, que tengan convenio activo con la misma, siempre que no hayan sido solventados en su totalidad por los servicios 
                médicos prestados previamente por ASISTENSI al BENEFICIARIO.

              <p className="my-4">
                Esta cobertura sólo es aplicable en caso de que haya sido específicamente contratada.
              </p>

                  <div className="my-2 ml-7 space-y-5">
                    <p>
                    Ante cualquier EMERGENCIA MÉDICA, <span className="underline">el BENEFICIARIO deberá ser atendido inicialmente por ASISTENSI (por vía remota o presencial) y ASISTENSI hará 
                    sus mejores esfuerzos para solventar la situación a través de sus servicios médicos o determinará si se trata de una EMERGENCIA MÉDICA que requiera ser tratada en un CENTRO DE
                    SALUD y solicitará la activación de la COBERTURA DE SALUD PARA EMERGENCIAS MÉDICAS EN CENTROS DE SALUD a MERCANTIL SEGUROS si la EMERGENCIA MÉDICA estuviera cubierta bajo la misma</span>.
                    </p>
                    <p>
                    En caso de EMERGENCIAS GRAVES (accidentes, inconsciencia, dolor torácico sugestivo de infarto al miocardio, etc.) cubiertas bajo la COBERTURA DE SALUD PARA EMERGENCIAS MÉDICAS EN CENTROS DE SALUD, 
                    el BENEFICIARIO podrá trasladarse por sus propios medios al CENTRO DE SALUD, siendo obligatorio llamar a la CENTRAL DE ALARMA TELEFÓNICA para agilizar la prestación del servicio en el CENTRO DE SALUD.
                    </p>
                    <p>
                    <b>3.5.1. Condiciones de admisibilidad del BENEFICIARIO para la COBERTURA DE SALUD PARA EMERGENCIAS MÉDICAS EN CENTROS DE SALUD:</b>
                    </p>
                    <ul className="ml-7 my-2 space-y-3">
                      <li>
                        3.5.1.1.  Tener una edad comprendida entre 0 y 120 años, inclusive. A los fines de los límites de edad establecidos, se considerará asegurada la persona hasta el vencimiento del año-póliza en el cual el BENEFICIARIO alcance la edad máxima fijada en cada caso.
                      </li>
                      <li>
                      3.5.1.2.  Ser familiar o tener una relación de afinidad con el TOMADOR del PLAN.
                      </li>
                      <li>
                      3.5.1.3.  Gozar de BUEN ESTADO DE SALUD al momento de la CONTRATACIÓN.
                      </li>
                      <li>
                      3.5.1.4.  No ser consumidor de ningún tipo de sustancias estupefacientes o psicotrópicas, salvo prescripción médica.
                      </li>
                      <li>
                      3.5.1.5.  Tener residencia habitual dentro del ÁMBITO TERRITORIAL.
                      </li>
                      <li>
                      3.5.1.6.  Ser titular de una MEMBRESÍA ASISTENSI vigente.
                      </li>

                    </ul>
                  </div>
              </p>
            </div>
          </div>
          <div>
            <p className="mt-6 my-3">
              <b>4. Proceso para solicitar la prestación de los servicios:</b>
            </p>
            <div className="ml-3">
              <p className="my-4">
              <b>4.1.</b> <span className="underline">Solicitud de servicio: </span>Ante cualquier EMERGENCIA MÉDICA que amerite una solicitud de servicio de ATENCIÓN MÉDICA IN SITU, ENTREGA DE MEDICAMENTOS, TRASLADOS, PRUEBAS DE LABORATORIOS CLÍNICOS o COBERTURA DE SALUD PARA EMERGENCIAS MÉDICAS EN CENTROS DE SALUD, el BENEFICIARIO deberá contactar a ASISTENSI a través de:
                <div className="my-2 ml-7">
                  <ul className="my-2 space-y-3 list-disc">
                    <li>
                      el aplicativo móvil disponible en las tiendas de APP: iOS y Android llamado “ASISTENSI”, indicando su número de identificación (cédula de identidad o pasaporte), su nombre, apellido y motivo de la llamada; o
                    </li>
                    <li>
                    los números de atención telefónica de la CENTRAL DE ALARMA TELEFÓNICA, indicando su número de identificación (cédula de identidad o pasaporte), su nombre, apellido y motivo de la llamada.
                    </li>
                  </ul>
                </div>
              </p>
            </div>
            <div className="ml-3">
              <p className="my-4">
              <b>4.2. </b><span className="underline">ASISTENCIA MÉDICA IN SITU.</span> Recibida su solicitud, ASISTENSI coordinará la prestación de ASISTENCIA MÉDICA IN SITU al BENEFICIARIO mediante el servicio de ORIENTACIÓN MÉDICA TELEFÓNICA o de ORIENTACIÓN MÉDICA VIRTUAL. 
              </p>
              <p className="my-4">
                En caso de que el equipo de salud de ASISTENSI, en función de los síntomas y manifestaciones subjetivas del BENEFICIARIO o acompañantes, lo considere necesario, podrá autorizar la 
                prestación del servicio de ATENCIÓN MÉDICA DOMICILIARIA y enviar un médico y paramédico al sitio en donde se encuentra el BENEFICIARIO para la realización de una consulta médica 
                que puede derivar en el diagnóstico y tratamiento en el sitio, y/o recomendar y autorizar un TRASLADO EN AMBULANCIA para la derivación del caso a un nivel superior de prestación 
                de servicios de salud.              </p>
            </div>
            <div className="ml-3">
              <p className="my-4">
              <span className="underline">TRASLADOS </span> Recibida su solicitud, ASISTENSI coordinará la prestación del TRASLADO al BENEFICIARIO mediante el servicio de ORIENTACIÓN MÉDICA TELEFÓNICA o de ORIENTACIÓN MÉDICA VIRTUAL. 
              </p>
            </div>
            <div className="ml-3">
              <p className="my-4">
                <span className="underline">ENTREGA DE MEDICAMENTOS: </span>Para hacer uso del servicio de ENTREGA DE MEDICAMENTOS el BENEFICIARIO deberá enviar el informe/récipe médico a través del email o a través del aplicativo móvil disponible en las tiendas de APP: iOS y Android llamado “ASISTENSI”, y haber recibido un récipe médico en una consulta médica privada o domiciliaria de ASISTENSI.   
                <p className="my-4">
                Una vez recibida la solicitud completa (solicitud con documentación del caso) se procederá a la validación del caso y confirmación de existencia del material, insumo y/o medicamento en los inventarios crítico y domiciliario, dando como resultado positivo el envío del inventario y/o provisión del servicio solicitado.
                </p>
              </p>
            </div>
            <div className="ml-3">
              <p className="my-4">
                <b>4.3. </b><span className="underline">COBERTURA DE SALUD PARA EMERGENCIAS MÉDICAS EN CENTROS DE SALUD: </span>
              </p>
              <p className="my-4">
                En caso de que esta cobertura haya sido contratada, ASISTENSI, a través de personal médico calificado, determinará si el BENEFICIARIO se encuentra ante una 
                EMERGENCIA MÉDICA que requiera su ingreso en un CENTRO DE SALUD por un riesgo cubierto por esta PÓLIZA. El BENEFICIARIO deberá solicitar a ASISTENSI, la prestación 
                del servicio de ASISTENCIA MÉDICA IN-SITU a los fines de evaluar la condición de salud presentada.
              </p>
              <p className="my-4">
                ASISTENSI a través de personal médico cualificado determinará si el BENEFICIARIO se encuentra ante una EMERGENCIA MÉDICA que amerite su ingreso en un CENTRO 
                DE SALUD por un riesgo cubierto por el contrato con MERCANTIL SEGUROS.
              </p>
              <p className="my-4">
                ASISTENSI trasladará al BENEFICIARIO a un CENTRO DE SALUD a los fines de brindarle atención médica por una EMERGENCIA MÉDICA que se encuentre cubierta por 
                el contrato y velará por que el BENEFICIARIO reciba atención inmediata. 
              </p>
              <p className="my-4">
                En caso de EMERGENCIA MÉDICA de carácter grave (accidentes, inconsciencia, dolor torácico sugestivo de infarto al miocardio, etc.), el BENEFICIARIO podrá 
                trasladarse por sus propios medios al CENTRO DE SALUD del sistema coordinado de proveedores, siendo obligatorio llamar a la CENTRAL DE ALARMA TELEFÓNICA para 
                recibir un mejor servicio en dicho CENTRO DE SALUD.
              </p>
              <p className="my-4">
                ASISTENSI apoyará en el procedimiento al BENEFICIARIO haciendo la comunicación directa con MERCANTIL SEGUROS para tramitar la activación de su cobertura.
              </p>
              <p className="my-4 underline">
                El BENEFICIARIO autoriza al médico tratante y al CENTRO DE SALUD a que comparta con ASISTENSI información acerca de su estado físico, historia clínica y demás circunstancias que originaron la atención, relevándolos de guardar el secreto médico. De igual manera, el BENEFICIARIO autoriza a ASISTENSI a solicitar y recibir cualquier tipo de información médica por parte del CENTRO DE SALUD y con MERCANTIL SEGUROS sobre la atención del BENEFICIARIO con un fin legítimo de prestación de servicio de los PLANES ASISTENSI.
              </p>
            </div>
          </div>
          <div>
            <p className="mt-6 my-3">
              <b>5. Periodo de carencia (o plazos de espera)</b>
            </p>
            <div className="ml-3">
              <p className="my-4">
              El BENEFICIARIO se considerará activo en el PLAN contratado una vez que el pago haya sido recibido en las cuentas indicadas en las instrucciones de pago por parte del personal o los sistemas de ASISTENSI en los siguientes plazos:
                <div className="my-2 ml-7">
                  <ul className="my-2 space-y-3">
                    <li>
                      <b>5.1. </b>ORIENTACIÓN MÉDICA TELEFÓNICA: activo veinticuatro (24) horas después del pago.
                    </li>
                    <li>
                      <b>5.2. </b>ORIENTACIÓN MÉDICA VIRTUAL:  activo veinticuatro (24) horas después del pago.
                    </li>
                    <li>
                      <b>5.3. </b>TRASLADOS: activo quince (15) días continuos después del pago.
                    </li>
                    <li>
                      <b>5.4. </b>ATENCIÓN MÉDICA DOMICILIARIA: activo quince (15) días continuos después del pago.
                    </li>
                    <li>
                      <b>5.5. </b>ENTREGA DE MEDICAMENTOS para casos de fase aguda/emergencias estará activo quince (15) días continuos después del pago (incluye uso desde la clínica y la consulta médica privada).
                    </li>
                    <li>
                      <b>5.6. </b> ENTREGA DE MEDICAMENTOS para casos programados: activo cuarenta y cinco (45) días continuos después del pago (uso con informe médico de procedimiento programado).
                    </li>
                    <li>
                      <b>5.7. </b>PRUEBAS DE LABORATORIOS CLÍNICOS: activo quince (15) días continuos después del pago.
                    </li>
                    <li>
                      <b>5.8. </b>COBERTURA DE SALUD PARA EMERGENCIAS MÉDICAS EN CENTROS DE SALUD: 

                      <div className="my-2 ml-3">
                        <ul className="my-2 space-y-3">
                          <li>
                            <b>5.8.1. </b> ASISTENSI incluirá en la PÓLIZA al BENEFICIARIO transcurridos quince (15) días continuos después del pago, siempre y cuando cumpla con las condiciones de admisibilidad previstas en la cláusula <b>3.5.1.</b>
                          </li>
                          <li>
                            <b>5.8.2. </b> Una vez incluido en la PÓLIZA al BENEFICIARIO, los periodos de espera aplicables a la COBERTURA DE SALUD PARA EMERGENCIAS MÉDICAS EN CENTROS DE SALUD serán los siguientes:

                            <ul className="mt-5 my-2 space-y-3 ml-7">
                              <li>
                                5.8.2.1. No se prevé periodo de espera tras incluir al BENEFICIARIO en la PÓLIZA y, por tanto, tendrán cobertura quince (15) días después del pago para aquellos accidentes amparados por la cobertura y por las siguientes enfermedades infecciosas agudas que generen una emergencia médica: fiebre reumática, apendicitis, bronquitis, gastroenteritis, abscesos (intraabdominales, intratorácicos e intracraneales), adenoiditis, vértigo o laberintitis, faringo – amigdalitis (sola o combinada), otitis, trastornos de la laringe, infección respiratoria baja, dengue hemorrágico, malaria, meningo-encefalitis, neumonía y pielonefritis, así como los gastos ocasionados por las siguientes enfermedades virales que generen una emergencia médica: parotiditis, rubéola, sarampión y varicela.
                              </li>
                              <li>
                              5.8.2.2. Se prevé un periodo de espera de tres (3) meses, es decir, tres (3) meses y quince (15) días después del pago, para cualquier emergencia médica que no esté expresamente excluida de la cobertura o listada en el numeral 5.8.2.1.
                              </li>
                              <li>
                              5.8.2.3. Se prevé un periodo de espera de seis (6) meses, es decir, seis (6) meses y quince (15) días después del pago, para aquellos BENEFICIARIOS que sufran de hipertensión arterial y se les haya otorgado cobertura de emergencias médicas, independientemente de lo expresado en los numerales 5.8.2.1 y 5.8.2.2.
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>

                    </li>
                  </ul>
                </div>
              </p>
            </div>
          </div>

          <div>
            <p className="mt-6 my-3">
              <b>6. Exclusiones del PLAN y COBERTURA DE SALUD PARA EMERGENCIAS MÉDICAS</b>
            </p>
            <div className="my-2 ml-3">
              <p>
              No serán cubiertos los gastos médicos y/o los siguientes servicios:
              </p>
              <ul className="ml-7 my-2 space-y-3">
                <li>
                <b>6.1. </b>Medicamentos prescritos por médicos fuera del SISTEMA COORDINADO DE PROVEEDORES (en la “Entrega de Medicamentos Domiciliarios”) sin la visita y verificación de un médico domiciliario o virtual de ASISTENSI.
                </li>
                <li>
                <b>6.2. </b>Servicios de traslado en ambulancia por tratamientos recurrentes y crónicos (ejemplo: diálisis, terapias).
                </li>
                <li>
                <b>6.3. </b>Tratamientos farmacológicos posteriores a la EMERGENCIA MÉDICA, salvo el servicio de ENTREGA DE MEDICAMENTOS previsto en la presente PÓLIZA
                </li>
                <li>
                <b>6.4. </b>Servicios de traslados en Aero ambulancia y/o terapia intensiva neonatal.
                </li>
                <li>
                <b>6.5. </b>Terapia intensiva neonatal.
                </li>
                <li>
                <b>6.6. </b>Personal de salud FIJO EN EL DOMICILIO DEL BENEFICIARIO.
                </li>
                <li>
                <b>6.7. </b>EMERGENCIAS MÉDICAS ocasionadas como consecuencia o se den en el curso de: guerra, invasión, acto de enemigo extranjero, hostilidades u operaciones bélicas (haya habido declaración de guerra o no), insubordinación militar, levantamiento militar, insurrección, rebelión, revolución, guerra civil, poder militar o usurpación de poder, proclamación del estado de excepción, acto de terrorismo o acto de cualquier persona que actúe en nombre de o en relación con alguna organización que realice actividades dirigidas a la sustitución por la fuerza del gobierno o influenciarlo mediante el terrorismo o la violencia.
                </li>
                <li>
                <b>6.8. </b>EMERGENCIAS MÉDICAS producidas por radiación nuclear, fisión, fusión, radiaciones ionizantes o contaminación radioactiva, salvo que se originen por una causa extraña no imputable al BENEFICIARIO.
                </li>
                <li>
                <b>6.9. </b>EMERGENCIAS MÉDICAS causadas por terremoto, temblor de tierra, maremoto, tsunami, inundación, movimientos de masas, flujos torrenciales, huracanes, tornado, tifón, ciclón, eventos climáticos, granizo, erupción volcánica o cualquier otra convulsión de la naturaleza o perturbación atmosférica.
                </li>
                <li>
                <b>6.10. </b>EMERGENCIAS MÉDICAS consecuencia de la participación activa del BENEFICIARIO en actos delictivos, motín, conmoción civil, disturbios populares, saqueos, disturbios laborales o conflictos de trabajo.
                </li>
                <li>
                <b>6.11. </b>Suicidio o sus tentativas, así como las lesiones que el BENEFICIARIO se cause intencionalmente.
                </li>
                <li>
                <b>6.12. </b>EMERGENCIAS MÉDICAS ocasionadas como consecuencia de la participación activa del BENEFICIARIO en duelos o riñas, a menos que se compruebe que no han sido provocados por el BENEFICIARIO o que éste actuó en legítima defensa.
                </li>
                <li>
                <b>6.13. </b>EMERGENCIAS MÉDICAS como consecuencia de la práctica del BENEFICIARIO de los siguientes deportes o actividades de alto riesgo: caza, automovilismo, motociclismo, motocross, karting, scooters, competencia de ciclismo, benji, buceo, submarinismo, esquí acuático, esquí en nieve, rafting, descensos de rápidos, surf, windsurf, remo, pesca en alta mar, pesca submarina, motonáutica, navegación en aguas internacionales en embarcaciones no destinadas al transporte público de pasajeros, fútbol americano, rugby, saltos ornamentales, paracaidismo, vuelo en ícaro, vuelo a vela, vuelo en globo, vuelo en parapente, vuelo en planeador, vuelo en ultraligero, velerismo, vuelo delta, coleo, competencia de equitación, polo, hipismo, rodeo, boxeo, lucha, artes marciales, jiu jitsu, viet vo dao, full contact, competencia de levantamiento de pesas, tiro, espeleología, alpinismo y escalada.
                </li>
                <li>
                <b>6.14. </b>EMERGENCIAS MÉDICAS producto de enfermedades decretadas como epidémicas por el organismo público competente, en lo que se refiere a los gastos que se hayan ocasionado luego de haber sido declaradas como tales, salvo ASISTENSI comunique lo contrario de manera expresa y explícita para enfermedades específicas.
                </li>
                <li>
                <b>6.15. </b>Tratamientos contra la dependencia del alcohol, de drogas estupefacientes y psicotrópicas y sus complicaciones, agudas o crónicas, el etilismo; así como los accidentes ocurridos bajo la influencia del alcohol o de drogas no prescritas médicamente y las consecuencias y/o enfermedades originadas por el consumo de alcohol o el uso de drogas no prescritas médicamente.
                </li>
                <li>
                <b>6.16. </b>Trasplantes de órganos o tejidos.
                </li>
                <li>
                <b>6.17. </b>EMERGENCIAS MÉDICAS que hayan sido ocasionadas por un trasplante de órgano o tejido.
                </li>
                <li>
                <b>6.18. </b>Tratamientos médicos y/o intervenciones quirúrgicas sobre órganos sanos con fines profilácticos.
                </li>
                <li>
                <b>6.19. </b>Curas de reposo, tratamientos de enfermedades psiquiátricas y mentales, exámenes y terapias efectuadas por médicos psiquiatras, trastornos funcionales de la conducta, neurosis, depresión, psicosis, esquizofrenia, deficiencias mentales, epilepsia, cuadros convulsivos repetitivos sin causa estructural, demencia senil, demencia presenil, trastornos bipolares, Alzheimer y los trastornos del sueño.
                </li>
                <li>
                <b>6.20. </b>. Chequeos médicos generales o exámenes con fines de diagnóstico o control, con o sin hospitalización, cuando no haya emergencia médica o no guarden relación con la enfermedad que originó la emergencia médica amparada por el contrato.
                </li>
                <li>
                <b>6.21. </b>Tratamientos experimentales o investigativos.
                </li>
                <li>
                <b>6.22. </b>Estados gripales, y aplicación de vacunas.
                </li>
                <li>
                <b>6.23. </b>Tratamientos experimentales o investigativos.
                </li>
                <li>
                <b>6.24. </b> Enfermedades de transmisión sexual, sus consecuencias y complicaciones
                </li>
                <li>
                <b>6.25. </b>Tratamiento quirúrgico de patología no tumoral de las glándulas mamarias. Mastoplastia con fines
                estéticos, funcionales o anatómicos y gigantomastia, Cúmulo de grasa pectoral o lipomastia en
                hombres.
                </li>
                <li>
                <b>6.26. </b> Tratamiento quirúrgico de patología no tumoral de las glándulas mamarias. Mastoplastia con fines
                estéticos, funcionales o anatómicos y gigantomastia, Cúmulo de grasa pectoral o lipomastia en
                hombres.
                </li>
                <li>
                <b>6.27. </b>Tratamientos y controles de la menopausia y la andropausia.
                </li>
                <li>
                <b>6.28. </b>Chequeos y controles ginecológicos
                </li>
                <li>
                <b>6.29. </b> Enfermedades y tratamientos odontológicos y periodontológicos, intervención quirúrgica de
                distonías miofaciales por mal posición dentaria y/o anomalías de crecimiento de maxilares,
                tratamientos de cualquier índole de la articulación temporomaxilar, salvo que los mismos sean
                originados como consecuencia de una emergencia médica que ocurra durante la vigencia del
                contrato, siempre que estos tratamientos se realicen, como máximo, dentro de los noventa (90)
                días continuos y siguientes a la fecha de ocurrencia del accidente.
                </li>
                <li>
                <b>6.30. </b> Embarazo, parto, cesárea, aborto y legrado uterino por aborto, sus consecuencias y complicaciones. Consultas y exámenes pre y post natal.
                </li>
                <li>
                <b>6.31. </b> Tratamientos médicos o intervención quirúrgica por disfunciones o insuficiencias sexuales,
                infertilidad, inseminación artificial, fertilización in vitro, impotencia, Peyronie, frigidez, esterilización,
                inversión de la esterilización, cambio de sexo y las complicaciones que se deriven de ellos, así como
                tratamientos anticonceptivos y sus consecuencias.
                </li>
                <li>
                <b>6.32. </b> Tratamientos para la obesidad o reducción de peso.
                </li>
                <li>
                <b>6.33. </b>Estudios, tratamientos y hospitalizaciones por alteraciones hormonales con repercusión en el crecimiento y/o desarrollo.
                </li>
                <li>
                <b>6.34. </b> Tratamientos para el Virus Inmunodeficiencia Humana (VIH) y/o el Síndrome de Inmunodeficiencia
                Adquirida (SIDA). No obstante, queda a salvo los gastos por emergencias médicas por las
                enfermedades originadas como consecuencia directa del Síndrome de Inmunodeficiencia
                Adquirida (SIDA) siempre y cuando el BENEFICIARIO esté en tratamiento para controlar dicha
                patología.
                </li>
                <li>
                <b>6.35. </b>Honorarios como consecuencia de cualquier tratamiento, proporcionado por un médico o
                enfermera que tenga parentesco con el BENEFICIARIO, dentro del segundo grado de
                consanguinidad o afinidad, o que viva con éstos.
                </li>
                <li>
                <b>6.36. </b>Impuestos, gastos de cobranza, limpieza, microfilm, historias clínicas, estacionamiento, misceláneos y otros costos no definidos ni relacionados con el tratamiento de la afección declarada.
                </li>
                <li>
                <b>6.37. </b>Tratamiento médico, intervención quirúrgica, servicios hospitalarios, procedimiento médico,
                medicamentos, suministros, equipos e instrumentos especiales, cuyas facturas no cumplan con las
                exigencias del Servicio Nacional Integrado de Administración Aduanera y Tributaria (SENIAT).
                </li>
                <li>
                <b>6.38. </b> La adquisición de los siguientes equipos médicos utilizados para controles de salud y/o
                prevención: tensiómetro, glucómetro, cepillos dentales, humidificador, irrigador dental, colchón
                de aire anti escaras y equipos de resucitación.
                </li>
                <li>
                <b>6.39. </b>Gastos médicos que no sean consecuencia de una emergencia médica del BENEFICIARIO.
                </li>
                <li>
                <b>6.40. </b>Cualquier ENFERMEDAD PREEXISTENTE o ENFERMEDAD CONGÉNITA que pueda padecer al
                momento de CONTRATACIÓN del PLAN, que sea de conocimiento o no del BENEFICIARIO, y sus
                posibles complicaciones médicas, no serán cubiertas por el alcance de la PÓLIZA para la
                COBERTURA DE SALUD PARA EMERGENCIAS MÉDICAS EN CENTROS DE SALUD.
                </li>
              </ul>
            </div>
          </div>
          <div>
            <p className="mt-6 my-3">
              <b>7. Exoneraciones de responsabilidad</b>
            </p>
            <div className="my-2 ml-3">
              <p>
              ASISTENSI quedará exonerada de toda responsabilidad de prestación de todos los servicios de los PLANES ASISTENSI, incluyendo la COBERTURA DE SALUD PARA EMERGENCIAS MÉDICAS EN CENTROS DE SALUD, en los siguientes casos:
              </p>
              <ul className="ml-7 my-2 space-y-3">
                <li>
                <b>7.1. </b> ASISTENSI no asumirá responsabilidad alguna en relación con la calidad del servicio recibido por el BENEFICIARIO y las consecuencias del mismo en virtud de los servicios prestados por otros centros o profesionales de salud diferentes a los del SISTEMA COORDINADO DE PROVEEDORES.
                </li>
                <li>
                <b>7.2. </b>Cuando la emergencia es originada por acciones criminales o dolosas del TOMADOR DEL PLAN o del BENEFICIARIO.
                </li>
                <li>
                <b>7.3. </b>Cuando la EMERGENCIA MÉDICA o accidente sea originada intencionalmente por el BENEFICIARIO, sus familiares o personas que convivan con él.
                </li>
                <li>
                <b>7.4. </b>Si el BENEFICIARIO suministra información falsa o inexacta u omitiere cualquier dato, que de haber sido conocido por ASISTENSI, éste no hubiese otorgado la cobertura o no lo hubiese hecho en las mismas condiciones.
                </li>
                <li>
                <b>7.5. </b>Si el TOMADOR, el BENEFICIARIO o cualquier persona que obre por cuenta de éstos, presenta una reclamación fraudulenta o engañosa, o si en cualquier tiempo emplea medios o documentos engañosos o dolosos para sustentar una reclamación o para derivar otros beneficios relacionados con este PLAN y/o la COBERTURA DE SALUD PARA EMERGENCIAS MÉDICAS.
                </li>
                <li>
                <b>7.6. </b>Si el siniestro ha sido ocasionado por culpa grave del TOMADOR o del BENEFICIARIO. No obstante, el MERCANTIL SEGUROS estará obligado al pago de la indemnización, si el siniestro ha sido ocasionado en cumplimiento de deberes legales de socorro o en tutela de intereses comunes con el MERCANTIL SEGUROS, en lo que respecta a la PÓLIZA.
                </li>
                <li>
                <b>7.7. </b>Si el siniestro se inicia antes de la vigencia del contrato, o durante los plazos de espera, y continúa después de que los riesgos hayan comenzado a correr por cuenta del MERCANTIL SEGUROS.
                </li>
                <li>
                <b>7.8. </b>Si el TOMADOR o el BENEFICIARIO no empleare los medios a su alcance para aminorar las consecuencias del siniestro, siempre que este incumplimiento se produjera con la manifiesta intención de perjudicar o engañar al MERCANTIL SEGUROS.
                </li>
                <li>
                <b>7.9. </b>Si el TOMADOR o el BENEFICIARIO o cualquier persona que obre por cuenta de éstos, actuando con dolo o culpa grave, obstaculiza los derechos del MERCANTIL SEGUROS estipulados en este PLAN.
                </li>
                <li>
                <b>7.10. </b>Si el tiempo para la confirmación del pago de la PÓLIZA por parte del personal o los sistemas de ASISTENSI se extiende un tiempo razonable en función de feriados, horarios laborales y otras causas, sean estas normales o de fuerza mayor.
                </li>
                <li>
                <b>7.11. </b>Si el TOMADOR DEL PLAN o el BENEFICIARIO no siguen el proceso de utilización de los servicios descrito en la cláusula 4 de los presentes TÉRMINOS Y CONDICIONES.
                </li>
              </ul>
            </div>
          </div>
          <div>
            <p className="mt-6 my-3">
              <b>8. Zonas de difícil acceso dentro del ámbito de cobertura</b>
            </p>
            <div className="my-2 ml-3">
              <p>
              Si el BENEFICIARIO que requiera el servicio de ASISTENSI se encontrara en áreas geográficas de notoria peligrosidad, difícil acceso o en horarios que impliquen situación de riesgo a discreción de ASISTENSI, el BENEFICIARIO estará obligado a realizar sus mejores esfuerzos para cooperar con el personal de ASISTENSI en la tarea de coordinar las actividades que sean necesarias, a los fines de tratar de garantizar la prestación del servicio, y dependerá de las circunstancias el que se pueda o no acceder al sitio, para atender y trasladar al BENEFICIARIO.
              </p>
              <p>
              Si por situaciones inesperadas y adversas en el proceso de visita y/o búsqueda no se pudiera acceder hasta el sitio indicado, ASISTENSI deberá contactar telefónicamente al BENEFICIARIO para establecer otras acciones a seguir.
              </p>
            </div>
          </div>
          <div>
            <p className="mt-6 my-3">
              <b>9. Vigencia de los PLANES y la COBERTURA DE SALUD PARA EMERGENCIAS MÉDICAS EN CENTROS DE SALUD</b>
            </p>
            <ul className="ml-7 my-2 space-y-3">
                <li><b>9.1. </b>Para cada BENEFICIARIO la cobertura estará vigente por el período correspondiente a un (1) año. 
                </li>
                <li><b>9.2.	</b>La PÓLIZA y sus posteriores modificaciones, si las hubiera, no tendrán efecto hasta que el TOMADOR abone a ASISTENSI la primera prima o 
                  fracción de la prima. Para los recibos anuales, trimestrales y semestrales se tendrá un plazo de gracia de quince (15) días. Sin perjuicio de la fecha 
                  de efecto de la PÓLIZA, tenga en cuenta los periodos de espera establecidos en el Artículo 5. 
                </li>
                <li><b>9.3.  </b>Si un TOMADOR deja de pagar las primas según lo anteriormente mencionado quedará automáticamente excluido de los PLANES ASISTENSI y la COBERTURA DE SALUD PARA EMERGENCIAS MÉDICAS, sin derecho a ser rehabilitado dentro de la base de datos y deberá realizar una nueva afiliación.
                </li>
            </ul>
          </div>
          <div>
            <p className="mt-6 my-3">
              <b>10. Tarifa por BENEFICIARIO</b>
            </p>
            <p className="my-4">
              El Plan ASISTENSI tendrá el costo indicado en las instrucciones de pago de acuerdo a la particularidad de la CONTRATACIÓN.
            </p>
          </div>

          <div>
            <p className="mt-6 my-3">
              <b>11. Terminación de los PLANES y política de reembolsos</b>
            </p>
            <div className="my-2 ml-3s">
              <p>
              Los PLANES terminarán para cada beneficiario en los siguientes supuestos:
              </p>
              <ul className="ml-3 my-2 space-y-3">
                <li>
                  <b>11.1. </b>Por la comunicación expresa por escrito del TOMADOR del PLAN a ASISTENSI:
                  <ul className="ml-7 my-2 space-y-3">
                    <li>
                      <b> 11.1.1. </b>Cuando la modalidad de pago de la prima sea mensual, el TOMADOR deberá notificar su deseo de terminar la PÓLIZA respecto de todos o algún BENEFICIARIO con una antelación de al menos treinta (30) días antes de la finalización de la última mensualidad para la que desee que la PÓLIZA esté en vigor.
                    </li>
                    <li>
                      <b> 11.1.2. </b>Cuando la modalidad de pago de la prima sea anual, semestral o trimestral, la PÓLIZA terminará respecto de todos los BENEFICIARIOS o aquellos indicados por el TOMADOR al finalizar la mensualidad en curso cuando se realizó la notificación y se reintegrarán las cantidades correspondientes según las siguientes reglas:
                      <ul className="ml-7 my-2 space-y-3">
                        <li>
                        11.1.2.1. Si la notificación se realizó dentro de los catorce (14) días siguientes a la CONTRATACIÓN de la PÓLIZA, inclusive, procederá el reembolso del 90% del monto total.
                        </li>
                        <li>
                        11.1.2.2. Si la notificación se realizó transcurridos catorce (14) días desde la CONTRATACIÓN de la PÓLIZA y antes de transcurrir treinta (30) días, procederá el reembolso del 30% del monto total.
                        </li>
                        <li>
                        11.1.2.3. Si la notificación se realizó transcurridos treinta (30) días o más desde la CONTRATACIÓN de la PÓLIZA, no procederá reembolso.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                <b>11.2. </b> . En caso de fallecimiento y/o cambio de residencia del BENEFICIARIO a una ciudad o país sin
                cobertura, el TOMADOR deberá comunicar expresamente esta circunstancia, dicha comunicación
                tendrá la consideración de una comunicación de cancelación y resultará de aplicación lo previsto
                en el apartado 11.1.1 o 11.1.2anterior, según corresponda, con lo que se tomará .en cuenta la fecha
                de la comunicación y no la fecha del fallecimiento o del cambio de residencia del BENEFICIARIO.
                </li>
                <li>
                <b>11.3. </b> Por el impago de las cuotas correspondientes.
                </li>
                <li>
                <b>11.4. </b> Cuando ASISTENSI o MERCANTIL SEGUROS tenga conocimiento por cualquier medio, que la información suministrada por el TOMADOR o el BENEFICIARIO es falsa o inexacta u omitiere cualquier dato, que de haber sido conocido por ASISTENSI o por MERCANTIL SEGUROS, ésta no hubiese otorgado la cobertura o no lo hubiese hecho en las mismas condiciones. En los casos descritos en este numeral no procederá reembolso alguno. No obstante, ASISTENSI ofrecerá la posibilidad de continuar con el PLAN contratado, excluyendo la COBERTURA DE SALUD PARA EMERGENCIAS MÉDICAS EN CENTROS DE SALUD.
                </li>
                <li>
                <b>11.5. </b>Cuando el BENEFICIARIO y/o el TOMADOR utilicen el servicio sin necesidad, de forma indiscriminada y excesiva, desvirtuando el objetivo del mismo. En este caso no procederá reembolso.
                </li>
                <li>
                <b>11.6. </b>Cuando el BENEFICIARIO y/o el TOMADOR tenga una conducta irrespetuosa o inadecuada con el personal del SISTEMA COORDINADO DE PROVEEDORES durante la prestación de los servicios. En este caso no procederá reembolso.
                </li>
                <li>
                <b>11.7. </b> De acuerdo a la cláusula 1.13 de Definiciones, cuando ASISTENSI o MERCANTIL SEGUROS tengan conocimiento de que el BENEFICIARIO tenga dos (2) PLANES contratados a la vez, ASISTENSI podrá cancelar el segundo PLAN ya que no está permitido tener dos (2) PLANES simultáneamente.
                </li>
              </ul>
            </div>
          </div>
          <div>
            <p className="mt-6 my-3">
              <b>12.	Pago de primas y efectos de su impago</b>
            </p>
            <div className="my-2 ml-0">
              <ul className="ml-3 my-2 space-y-3">
                <li>
                <b>12.1. </b>Las primas o, en su caso, fracciones de prima, serán pagaderas en el momento y con la periodicidad que se indica en el cuadro de PÓLIZA. El medio de pago también se indicará en el cuadro de PÓLIZA.
                </li>
                <li>
                <b>12.2. </b>En caso de impago de la primera prima o primera fracción de la prima la PÓLIZA no tendrá efecto. 
                </li>
                <li>
                  <b>12.3. </b>En caso de falta de pago de una de las primas siguientes a la primera o de una fracción de la prima siguiente a la primera, la cobertura de la PÓLIZA quedará en suspenso un (1) mes después de la fecha en que debió pagarse la prima o fracción de la prima. Si el ASEGURADOR no reclama el pago dentro de los seis (6) meses siguientes al vencimiento de la prima se entenderá que la PÓLIZA queda extinguida.
                </li>
                <li>
                  <b>12.4. </b>El TOMADOR será notificado cuando exista la situación de un pago fallido para que pueda tomar las medidas que estime adecuadas en los plazos que resulten de aplicación.
                </li>
                <li>
                  <b>12.5. </b>La Anulación aquí mencionada no es impedimento para que EL TOMADOR DEL PLAN pueda volver a realizar el proceso de contratación del PLAN.
                </li>
              </ul>
            </div>
          </div>


          <div>
            <p className="mt-6 my-3">
              <b>13. Modificaciones a los TÉRMINOS Y CONDICIONES</b>
            </p>
            <p className="ml-3">
              Durante la vigencia de la póliza los TÉRMINOS Y CONDICIONES pueden modificarse para incorporar nuevos tratamientos y medios de diagnóstico, modificaciones legislativas o 
              cualquier otro tipo de precisión sobre las coberturas. Cualquier modificación que implique una reducción de coberturas médicas deberá ser comunicada expresamente al 
              TOMADOR en un periodo de diez (10) días hábiles mediante la notificación mediante correo electrónico a los TOMADORES DEL PLAN, a fin de que éstos tengan la oportunidad 
              de manifestar si desean continuar bajo el nuevo clausulado o terminar la relación contractual, para sí y los correspondientes BENEFICIARIOS. Se entenderá como aceptación 
              de los nuevos TÉRMINOS Y CONDICIONES, (i) la aceptación expresa por parte del TOMADOR DEL PLAN; (ii) el silencio del TOMADOR DEL PLAN transcurridos diez (10) días desde 
              la notificación de la modificación de los TÉRMINOS Y CONDICIONES; y (iii) el uso continuado por parte del BENEFICIARIO de los servicios, después de recibir la notificación 
              sobre los cambios en los TÉRMINOS Y CONDICIONES.
            </p>
          </div>

          <div>
            <p className="mt-10 my-3 text-center">
              <b>Anexo 1.30 <br/> CONDICIONES PARTICULARES DE LA COBERTURA DE SALUD PARA EMERGENCIAS MÉDICAS EN CENTROS DE SALUD OTORGADA DIRECTAMENTE POR MERCANTIL SEGUROS (EL “ASEGURADOR”) A LOS BENEFICIARIOS AMPARADOS POR LOS PLANES ASISTENSI (CONDICIONES PARTICULARES DE LA “PÓLIZA”)</b>
            </p>
            <div>
              <p className="ml-0">
                <b>
                CLÁUSULA 1. INTERPRETACIÓN DE TÉRMINOS
                </b>
              </p>
              <p className="my-4 ml-0">
                Los términos en mayúscula no definidos tendrán el significado asignado a los mismos en el Plan Asistensi.              
              </p>
              <p className="my-4 ml-0">
              A estos efectos , los términos que se señalan a continuación tendrán el siguiente significado:
              </p>
              <div className="my-2 ml-5">
                <ul className="ml-3 my-2 space-y-3 list-disc">
                  <li>
                    <b>ENFERMEDAD PREEXISTENTE: </b>Enfermedad o lesión que pueda comprobarse ha sido adquirida con anterioridad a la fecha de inicio de la vigencia del contrato o de la inclusión del BENEFICIARIO en la Póliza, y sea conocida por ASISTENSI o el BENEFICIARIO.
                  </li>
                  <li>
                    <b>MÉDICO: </b>Profesional de la medicina titulado e inscrito en el Ministerio con competencia en materia de salud o en la institución que legalmente corresponda, para ejercer la profesión médica en el país donde presta sus servicios.
                  </li>
                  <li>
                    <b>TRATAMIENTO EXPERIMENTAL O INVESTIGATIVO: </b>Tratamiento médico, intervención quirúrgica, suministro, medicamento, procedimiento médico u hospitalización que:
                    <div className="my-2 ml-5">
                      <ul className="ml-3 my-2 space-y-3 list-decimal">
                        <li>
                          No haya sido aceptado como seguro, efectivo y apropiado para el tratamiento médico de enfermedades por el consenso de las organizaciones profesionales que están reconocidas por la comunidad médica internacional; o
                        </li>
                        <li>
                          Su uso esté restringido a objetivos clínicos disciplinados que posean valor o beneficio para propósitos clínicos de la disciplina y estudios científicos; o
                        </li>
                        <li>
                          No se haya probado de manera objetiva que posea valor o beneficio terapéutico; o
                        </li>
                        <li>
                          Esté bajo estudio, investigación, en un período de prueba o en cualquier fase de un experimento o ensayo clínico.
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <b>PRÓTESIS: </b>Dispositivo o aparato diseñado para reemplazar una parte faltante del cuerpo o para hacer que una parte del cuerpo trabaje mejor.
                  </li>
                  <li>
                    <b>TRATAMIENTO MÉDICO: </b>Conjunto de medidas realizadas u ordenadas por un médico que se ponen en práctica para la curación o alivio de una enfermedad o lesión, incluyendo medicamentos prescritos, insumos o PRÓTESIS.
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <p className="mt-7 ml-0">
                <b>
                Cláusula 2. COBERTURAS DE LA PÓLIZA
                </b>
              </p>
              <p className="my-4 ml-0">
                Es la Cobertura prestada por MERCANTIL SEGUROS que ampara los gastos médicos ocasionados durante la vigencia por una 
                EMERGENCIA MÉDICA en el territorio de la República Bolivariana de Venezuela que amerite la atención del BENEFICIARIO 
                en una clínica en virtud de no haber sido solventada en su totalidad la atención del BENEFICIARIO por los servicios 
                médicos prestados previamente por ASISTENSI con un límite de CINCO MIL DÓLARES AMERICANOS, USD. 5.000 o DIEZ MIL DÓLARES 
                AMERICANOS, USD 10.000 que serán aplicados por BENEFICIARIO, por enfermedad o accidente y vigencia anual, pagaderos en 
                Bolívares a la clínica, calculados en base al FACTOR DE TASA DE CAMBIO. El BENEFICIARIO será atendido inicialmente por 
                ASISTENSI (por vía remota o presencial), quien determinará que se trata de una emergencia médica que requiere ser tratada 
                en una clínica y velará para que el BENEFICIARIO reciba atención inmediata en un CENTRO DE SALUD. En caso de emergencias 
                graves (accidentes, inconsciencia, dolor torácico sugestivo de infarto al miocardio, etc) el BENEFICIARIO podrá trasladarse 
                por sus propios medios a la clínica, siendo recomendable llamar a la central de alarma telefónica para recibir un mejor 
                servicio en la clínica.
              </p>
              <p className="my-4 ml-0">
                PLAN ASISTENSI: USD$5,000.00 por caso o evento por año
              </p>
              <p className="my-4 ml-0">
                PLAN ASISTENSI PLUS: USD$10,000.00 por caso o evento por año
              </p>
              <p className="my-4 ml-0">
                La cobertura a la que tendrán derecho los BENEFICIARIOS de las Pólizas de Salud es la que se describe a continuación, conforme 
                a la PÓLIZA y al PLAN ASISTENSI elegido por el TOMADOR para los correspondientes BENEFICIARIOS, según lo notificado por 
                ASISTENSI a MERCANTIL SEGUROS:
              </p>
              <div className="my-2 ml-5">
                <p className="my-4 ml-0">
                  El servicio cubierto puede incluir sin limitarse, las siguientes coberturas:
                </p>
                <ul className="ml-3 my-2 space-y-3 list-disc">
                  <li> Sala de Emergencia</li>
                  <li>Honorarios médicos por emergencia</li>
                  <li>Medicamentos en emergencia (excluyendo los antirretrovirales)</li>
                  <li>Material gastable</li>
                  <li>Exámenes de diagnóstico realizados en emergencia</li>
                  <li>Estudios especiales realizados en emergencia</li>
                  <li>Cirugías realizadas en emergencia</li>
                  <li>Anestesia</li>
                  <li>Nebulizador</li>
                  <li>Laboratorios realizados en emergencia</li>
                  <li>Rayos X</li>
                  <li>Sonografías</li>
                  <li>Uso de equipos en emergencia</li>
                </ul>             
              </div>

              <p className="my-4 ml-0">
                Bajo el servicio de Emergencia también quedan cubiertos los gastos incurridos por el BENEFICIARIO de la PÓLIZA 
                a raíz de un diagnóstico positivo de Covid-19, siempre que MERCANTIL SEGUROS decida mantener su decisión 
                de amparar esta pandemia (excluida de su Condicionado aprobado). Quedan excluidos de este servicio los gastos 
                relacionados a cualquier prueba diagnóstica realizada para la detección o seguimiento de Covid-19, incluyendo 
                las pruebas PCR, pruebas virales o de anticuerpos en cualquiera de sus variantes. La prueba de Covid-19 PCR 
                será cubierta si el BENEFICIARIO de la PÓLIZA se encuentra hospitalizado y tiene un diagnóstico previo de Covid-19.
              </p>

              <p className="my-4 ml-0">
                <span className="underline">Servicios de Hospitalización:</span>{" "}
                bajo este beneficio quedan cubiertos los gastos relacionados a la hospitalización de la persona asegurada 
                siempre que la misma sea realizada en un Prestador de Servicios de Salud y que la causa que origine 
                dicha hospitalización no esté relacionada con una condición o ENFERMEDAD PREEXISTENTE a la entrada o 
                inclusión del Beneficiario de la Póliza de Salud. Dicho servicio debe ser autorizado por el Centro de Atención 
                al Cliente de MERCANTIL SEGUROS al momento de su ingreso y sujeto a la debida pertinencia médica para la 
                prestación del servicio.
              </p>

              <p className="my-4 ml-0">
                Las hospitalizaciones cubiertas bajo la Póliza de Salud serán las derivadas de emergencia o urgencia médica a consecuencia de un accidente o enfermedad común contraídos por el Beneficiario de la Póliza de Salud durante inclusión en ésta. Estos servicios pueden incluir sin limitarse, las siguientes coberturas:
              </p>

              <div className="my-2 ml-5">
                <ul className="ml-3 my-2 space-y-3 list-disc">
                  <li>Habitación</li>
                  <li>Medicamentos (excluyendo los antirretovirales)</li>
                  <li>Material gastable</li>
                  <li>Exámenes de diagnóstico</li>
                  <li>Estudios especiales</li>
                  <li>Consultas en internamiento</li>
                  <li>Honorarios médico-quirúrgicos</li>
                  <li>Anestesia</li>
                  <li>Sala de cirugía</li>
                  <li>Unidad de Cuidados Intensivos</li>
                  <li>Terapia respiratoria</li>
                  <li>Gastos del acompañante no están incluidos dentro de estos gastos.</li>
                </ul>
              </div>

              <p className="my-4 ml-0">
                Dentro de los servicios de hospitalización quedan cubiertas las intervenciones quirúrgicas derivadas de los 
                casos de emergencia que requieran urgentemente de dicha intervención, siempre que estos sean autorizados 
                por el Departamento Médico y el Centro de Atención al Cliente de MERCANTIL SEGUROS antes de realizarse la 
                misma. Si durante la hospitalización y a consecuencia de complicaciones debidas a la intervención quirúrgica 
                o por cualquier otra enfermedad no preexistente, el Beneficiario de la Póliza de Salud requiere atención médica 
                por parte de uno o varios especialistas distintos al cirujano, además de los honorarios médicos del cirujano, MERCANTIL 
                SEGUROS reconocerá una (1) visita médica diaria para cada uno de los especialistas que intervinieron en la complicación.
              </p>

              <p className="my-4 ml-0">
                Bajo este servicio de hospitalización también quedan cubiertas las hospitalizaciones, incluyendo el seguimiento 
                en remoto y las hospitalizaciones de tipo domiciliario, derivadas de un diagnóstico positivo de Covid -19 siempre 
                que esta hospitalización haya sido requerida por un profesional de la salud y que dicha hospitalización sea realizada 
                en un proveedor de servicios de salud de la red de MERCANTIL SEGUROS, siempre que MERCANTIL SEGUROS decida mantener 
                su decisión de amparar esta pandemia (excluida de su Condicionado aprobado).
              </p>

              <p className="my-4 ml-0">
                <span className="underline">Uso de la Unidad de Cuidados Intensivos (UCI):</span>{" "} cuando por la naturaleza de la ENFERMEDAD o ACCIDENTE sufrido 
                por el BENEFICIARIO de la PÓLIZA durante su inclusión en ésta, sea médicamente necesaria el uso de este 
                beneficio, MERCANTIL SEGUROS gestionará la cobertura de este a la persona asegurada siempre con autorización de 
                su Departamento Médico y de su Centro de Atención al Cliente, hasta el límite estipulado en la PÓLIZA.
              </p>

              <p className="my-4 ml-0">
                <span className="underline">Médicamente Necesarios (Urgencias Médica):</span>{" "} Conjunto de medidas o procedimientos 
                ordenados y suministrados por un médico o CENTRO DE SALUD, que se ponen en práctica para el tratamiento, curación 
                o alivio de una estricta emergencia médica, bajo las siguientes características: 1. Que sea apropiado para 
                el diagnóstico y tratamiento de la ENFERMEDAD o lesión del BENEFICIARIO. 2. Que sea congruente con las normas 
                profesionales aceptadas en la práctica de la medicina por la comunidad médica del país donde se presta el 
                servicio o tratamiento. 3. Que el nivel de servicio o suministro sea idóneo y pueda ser proporcionado sin riesgo 
                para el BENEFICIARIO. 4. Que no sea primordialmente para el confort o la conveniencia personal del BENEFICIARIO, de 
                su familia o de su Médico.
              </p>

              <p className="my-4 ml-0">
                <span className="underline">Otras Coberturas: </span>Previo a la aprobación de MERCANTIL SEGUROS, podrán 
                incorporarse nuevas coberturas, siempre que así se especifique y se actualice en la PÓLIZA y se cobre 
                la prima correspondiente para dar dicha cobertura a los BENEFICIARIOS de la PÓLIZA.
              </p>

              <p className="my-4 ml-0">
                A. Servicios y coberturas por Maternidad. Quedan excluidos de la cobertura para los servicios de emergencia 
                y hospitalización los gastos relacionados a la maternidad de la persona asegurada o de alguna de sus dependientes 
                incluidas en la PÓLIZA, entendiéndose por maternidad los gastos relacionados a partos, cesáreas (sean estos prematuros 
                o no), abortos provocados o cualquier complicación derivada de esta condición, así como también los gastos 
                relacionados al cuidado del recién nacido y sus complicaciones relacionadas con su nacimiento.
              </p>

              <p className="my-4 ml-0">              
                B. Cobertura en Exceso. En caso de que el Beneficiario de la PÓLIZA utilice como primera opción un seguro de salud 
                contratado por este de forma directa o no, con otra aseguradora local o internacional, MERCANTIL SEGUROS no otorgará 
                cobertura en exceso de ningún otro plan o producto de salud existente al momento de la prestación del servicio.
              </p>
            </div>
            <div>
              <p className="mt-7 ml-0">
                <b>
                CLÁUSULA 3. PLAZOS DE ESPERA
                </b>
              </p>
              <p className="my-4 ml-0">
                Una vez incluido en la PÓLIZA al BENEFICIARIO, los periodos de espera aplicables a la COBERTURA DE SALUD PARA 
                EMERGENCIAS MÉDICAS EN CENTROS DE SALUD serán los siguientes:
              </p>

              <ul className="ml-7 my-2 space-y-3">
                <li className="flex"><span className="mr-3">3.1. </span> <span  className="text-justify"> No se prevé periodo de espera tras incluir al BENEFICIARIO en la PÓLIZA y, por tanto, tendrán 
                  cobertura quince (15) días después de confirmar el pago para aquellos accidentes amparados por la cobertura 
                  y por las siguientes enfermedades infecciosas agudas que generen una emergencia médica: fiebre reumática, 
                  apendicitis, bronquitis, gastroenteritis, abscesos (intraabdominales, intratorácicos e intracraneales), adenoiditis, 
                  vértigo o laberintitis, faringo – amigdalitis (sola o combinada), otitis, trastornos de la laringe, infección 
                  respiratoria baja, dengue hemorrágico, malaria, meningo-encefalitis, neumonía y pielonefritis, así como los gastos 
                  ocasionados por las siguientes enfermedades virales que generen una emergencia médica: parotiditis, rubéola, 
                  sarampión y varicela. </span>
                </li>
                <li className="flex"><span className="mr-3">3.2.</span> <span>Se prevé un periodo de espera de tres (3) meses, para cualquier EMERGENCIA MÉDICA ue no esté 
                  expresamente excluida de la cobertura o listada en el numeral 3.1.</span>
                </li>
                <li className="flex"><span className="mr-3">3.3.</span> <span>Se prevé un periodo de espera de seis (6) meses para cualquier EMERGENCIA MÉDICA relacionada con 
                  cuadros cardiovasculares para aquellos BENEFICIARIOS que sufran de hipertensión arterial y se les haya otorgado 
                  cobertura de emergencias médicas, independientemente de lo expresado en los numerales 3.1 y 3.2.</span>
                </li>
            </ul>
            </div>

            <div>
              <p className="mt-7 ml-0">
                <b>
                CLÁUSULA 4. EXCLUSIONES
                </b>
              </p>
              <p className="my-4 ml-0">
                El ASEGURADOR no cubre los gastos relacionados con:              </p>
              <div className="my-2 ml-5">
                <ul className="ml-3 my-2 space-y-3 list-decimal">
                  <li>
                    EMERGENCIAS MÉDICAS ocasionadas como consecuencia o se den en el curso de: guerra, invasión, acto de enemigo 
                    extranjero, hostilidades u operaciones bélicas (haya habido declaración de guerra o no), insubordinación 
                    militar, levantamiento militar, insurrección, rebelión, revolución, guerra civil, poder militar o usurpación de 
                    poder, proclamación del estado de excepción, acto de terrorismo o acto de cualquier persona que actúe en nombre 
                    de o en relación con alguna organización que realice actividades dirigidas a la sustitución por la fuerza del gobierno 
                    o influenciarlo mediante el terrorismo o la violencia.
                  </li>
                  <li>
                    EMERGENCIAS MÉDICAS producidas por radiación nuclear, fisión, fusión, radiaciones ionizantes o contaminación 
                    radioactiva, salvo que se originen por una causa extraña no imputable al BENEFICIARIO.
                  </li>
                  <li>
                    EMERGENCIAS MÉDICAS causadas directa o indirectamente por eventos catastróficos de origen natural o 
                    provocados por el hombre de forma accidental o voluntaria, tales como terremoto, temblor de tierra, 
                    maremoto, tsunami, inundación, movimientos de masas, flujos torrenciales, huracanes, tornado, tifón, ciclón, 
                    eventos climáticos, granizo, erupción volcánica o cualquier otra convulsión de la naturaleza o perturbación atmosférica.                  
                  </li>
                  <li>
                    EMERGENCIAS MÉDICAS consecuencia de la participación activa del BENEFICIARIO en actos delictivos, motín, 
                    conmoción civil, disturbios populares, saqueos, disturbios laborales o conflictos de trabajo.                  
                  </li>
                  <li>
                    Suicidio o sus tentativas, así como las lesiones que el BENEFICIARIO se cause intencionalmente.
                  </li>
                  <li>
                    EMERGENCIAS MÉDICAS ocasionadas como consecuencia de la participación activa del BENEFICIARIO en duelos o riñas, a 
                    menos que se compruebe que no han sido provocados por el BENEFICIARIO o que éste actuó en legítima defensa.
                  </li>
                  <li>
                    EMERGENCIAS MÉDICAS como consecuencia de la práctica del BENEFICIARIO de los siguientes deportes o actividades 
                    de alto riesgo: caza, automovilismo, motociclismo, motocross, karting, scooters, competencia de ciclismo, benji, buceo, 
                    submarinismo, esquí acuático, esquí en nieve, rafting, descensos de rápidos, surf, windsurf, remo, pesca en alta mar, 
                    pesca submarina, motonáutica, navegación en aguas internacionales en embarcaciones no destinadas al transporte público 
                    de pasajeros, fútbol americano, rugby, saltos ornamentales, paracaidismo, vuelo en ícaro, vuelo a vela, vuelo en globo, 
                    vuelo en parapente, vuelo en planeador, vuelo en ultraligero, velerismo, vuelo delta, coleo, competencia de equitación, 
                    polo, hipismo, rodeo, boxeo, lucha, artes marciales, jiu jitsu, viet vo dao, full contact, competencia de levantamiento 
                    de pesas, tiro, espeleología, alpinismo y escalada.
                  </li>
                  <li>
                    EMERGENCIAS MÉDICAS producto de ENFERMEDADES decretadas como epidémicas por el organismo público competente, en lo que 
                    se refiere a los gastos que se hayan ocasionado luego de haber sido declaradas como tales.
                  </li>
                  <li>
                    Tratamientos contra la dependencia del alcohol, de drogas estupefacientes y psicotrópicas y sus complicaciones, agudas o 
                    crónicas, el etilismo; así como los accidentes ocurridos bajo la influencia del alcohol o de drogas no prescritas 
                    médicamente y las consecuencias y/o enfermedades originadas por el consumo de alcohol o el uso de drogas no prescritas 
                    médicamente.
                  </li>
                  <li>
                    Trasplantes de órganos o tejidos.
                  </li>
                  <li>
                    EMERGENCIAS MÉDICAS que hayan sido ocasionadas por un trasplante de órgano o tejido.
                  </li>
                  <li>
                    TRATAMIENTOS MÉDICOS y/o intervenciones quirúrgicas sobre órganos sanos con fines profilácticos.
                  </li>
                  <li>
                    Curas de reposo,TRATAMIENTOS MÉDICOS de enfermedades psiquiátricas y mentales,exámenes y terapias efectuadas por 
                    médicos psiquiatras, trastornos funcionales de la conducta, neurosis, depresión, psicosis, esquizofrenia, deficiencias 
                    mentales, epilepsia, cuadros convulsivos repetitivos sin causa estructural, demencia senil, demencia presenil, trastornos 
                    bipolares, alzhéimer y los trastornos del sueño.
                  </li>
                  <li>
                    Chequeos médicos generales o exámenes con fines de diagnóstico o control, con o sin hospitalización, cuando no haya 
                    emergencia médica o no guarden relación con la enfermedad que originó la emergencia médica amparada por la Póliza. Estados 
                    gripales, salvo lo señalado en la Cláusula 4 e estas Condiciones Particulares, y aplicación de vacunas. Acupuntura, medicina 
                    naturista, homeopática o cualquier otro tratamiento no aceptado por la Federación Médica Venezolana.
                  </li>
                  <li>
                    TRATAMIENTO EXPERIMENTAL O INVESTIGATIVO.
                  </li>
                  <li>
                    ENFERMEDADES de transmisión sexual,sus consecuencias y complicaciones.
                  </li>
                  <li>
                    El traslado del BENEFICIARIO desde y hasta el CENTRO DE SALUD.
                  </li>
                  <li>
                    Consultas o exámenes de la vista, corrección de vicios o defectos de refracción visual (miopía, hipermetropía, 
                    astigmatismo, presbicia) por tratamientos quirúrgicos o no quirúrgicos (anteojos y/o lentes de contacto), lentes 
                    intraoculares o infraoculares.
                  </li>
                  <li>
                    Tratamiento quirúrgico de patología no tumoral de las glándulas mamarias. Mastoplastia con fines estéticos, funcionales 
                    o anatómicos y gigantomastia, Cúmulo de grasa pectoral o lipomastia en hombres.
                  </li>
                  <li>
                    Tratamientos y controles de la menopausia y la andropausia.
                  </li>
                  <li>Chequeos y controles ginecológicos. </li>
                  <li>Tratamientos médicos o intervención quirúrgica cosmética, plástica o estética.</li>
                  <li>Enfermedades y tratamientos odontológicos y periodontológicos, intervención quirúrgica de 
                    distonías miofaciales por mal posición dentaria y/o anomalías de crecimiento de maxilares, 
                    tratamientos de cualquier índole de la articulación temporomaxilar.
                  </li>
                  <li>Embarazo,parto,cesárea,abortoylegradouterinoporaborto,susconsecuenciasycomplicaciones. Consultas 
                    y exámenes pre y post natal.
                  </li>
                  <li>Tratamientos médicos o intervención quirúrgica por disfunciones o insuficiencias sexuales, 
                    infertilidad, inseminación artificial, fertilización in vitro, impotencia, Peyronie, frigidez, 
                    esterilización, inversión de la esterilización, cambio de sexo y las complicaciones que se deriven 
                    de ellos, así como tratamientos anticonceptivos y sus consecuencias.
                  </li>
                  <li>Tratamientos para la obesidad o reducción de peso.</li>
                  <li>Estudios, tratamientos y hospitalizaciones por alteraciones hormonales con repercusión en el 
                    crecimiento y/o desarrollo.
                  </li>
                  <li>Tratamientos para el Virus Inmunodeficiencia Humana (VIH) y/o el Síndrome de Inmunodeficiencia 
                    Adquirida (SIDA). No obstante, queda a salvo lo establecido en el numeral 15 de la Cláusula 2. Gastos 
                    Cubiertos, de estas Condiciones Particulares.
                  </li>
                  <li>Honorarios como consecuencia de cualquier tratamiento, proporcionado por un médico o enfermera que 
                    tenga parentesco con el BENEFICIARIO, dentro del segundo grado de consanguinidad o afinidad, o que viva 
                    con éstos.
                  </li>
                  <li>Impuestos,gastosdecobranza,limpieza,microfilm,historiasclínicas,estacionamiento,misceláneos y otros 
                    costos no definidos ni relacionados con el tratamiento de la afección declarada.
                  </li>
                  <li>TRATAMIENTO MÉDICO, intervención quirúrgica, servicios hospitalarios, procedimiento 
                    médico, medicamentos, suministros, equipos e instrumentos especiales, cuyas facturas no cumplan con las 
                    exigencias del Servicio Nacional Integrado de Administración Aduanera y Tributaria (SENIAT).
                  </li>
                  <li>Medicamentos con o sin prescripción facultativa, relacionados o no con la enfermedad o lesión 
                    tratada, salvo los empleados por un CENTRO DE SALUD para la atención de una EMERGENCIA MÉDICA del 
                    BENEFICIARIO cubierta por la PÓLIZA.
                  </li>
                  <li>Laadquisicióndelossiguientesequiposmédicosutilizadosparacontrolesdesaludy/oprevención: tensiómetro, 
                    glucómetro, cepillos dentales, humidificador, irrigador dental, colchón de aire anti escaras y equipos de 
                    resucitación.
                  </li>
                  <li>Los gastos médicos generados por ASISTENSI durante la atención del BENEFICIARIO.</li>
                  <li>Gastos médicos que no sean consecuencia de una emergencia médica del Beneficiario.</li>
                </ul>
              </div>
            </div>
            <div>
              <p className="mt-7 ml-0">
                <b>
                CLÁUSULA 5. OTRAS EXONERACIONES DE RESPONSABILIDAD
                </b>
              </p>
              <p className="my-4 ml-0">
              El ASEGURADOR no estará obligado al pago de la indemnización en los siguientes casos:
              </p>
              <div className="my-2 ml-5">
                <ul className="ml-3 my-2 space-y-3 list-disc">
                  <li>
                    Si ASISTENSI o el BENEFICIARIO no cumpliere con lo establecido en la Cláusula 6. Procedimiento en caso de Activación de la Cobertura, de estas Condiciones Particulares, salvo por causa extraña no imputable a ASISTENSI o al BENEFICIARIO.
                  </li>
                  <li>
                    Si ASISTENSI no paga, por causas imputables al TOMADOR DEL PLAN, la prima en los términos establecidos en las Condiciones Generales y Particulares de la PÓLIZA.
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <p className="mt-7 ml-0">
                <b>
                CLÁUSULA 6. PROCEDIMIENTO EN CASO DE ACTIVACIÓN DE LA COBERTURA
                </b>
              </p>
              <p className="my-4 ml-0">
              Si cualquier Beneficiario requiere la prestación de servicios garantizados por esta Póliza, se tomará en cuenta lo siguiente:
              </p>
              <div className="my-2 ml-5">
                <ul className="ml-3 my-2 space-y-3 list-decimal">
                  <li>
                    El BENEFICIARIO deberá solicitar a ASISTENSI, la prestación del servicio de asistencia médica a los fines de evaluar la 
                    condición de salud presentada.                  
                  </li>
                  <li>
                    ASISTENSI a través de personal médico calificado determinará si el BENEFICIARIO se encuentra ante una emergencia médica que 
                    amerite su ingreso en un CENTRO DE SALUD por un riesgo cubierto por la PÓLIZA.                  
                  </li>
                  <li>
                    ASISTENSI trasladará al BENEFICIARIO al CENTRO DE SALUD a los fines de brindarle la atención médica por una EMERGENCIA MÉDICA 
                    que se encuentre cubierta por la presente PÓLIZA y velará por que el BENEFICIARIO reciba atención inmediata.                  
                  </li>
                  <li>
                    El ASEGURADOR utilizará todos los mecanismos necesarios para procurar que el BENEFICIARIO reciba atención inmediata.                  
                  </li>
                  <li>
                    El BENEFICIARIO autoriza al médico tratante y al CENTRO DE SALUD para dar información acerca de su estado físico, historia clínica 
                    y demás circunstancias que originaron la reclamación, relevándolos de guardar el secreto médico.                  
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <p className="mt-7 ml-0">
                <b>
                CLÁUSULA 7. PERSONAS ASEGURABLES
                </b>
              </p>
              <p className="my-4 ml-0">
                Son asegurables bajo la presente PÓLIZA, las siguientes personas:              
              </p>
              <div className="my-2 ml-5">
                <ul className="ml-3 my-2 space-y-3 list-decimal">
                  <li>
                    Con edades comprendidas entre 0 y 120 años, inclusive. A los fines de los límites de edad establecidos, se 
                    considerará asegurada la persona hasta el vencimiento del año-póliza en el cual el BENEFICIARIO alcance la 
                    edad máxima fijada en cada caso.
                  </li>
                  <li>
                    Ser familiar o tener una relación de afinidad con el TOMADOR DEL PLAN ASISTENSI.
                  </li>
                  <li>
                    Deben gozar de BUEN ESTADO DE SALUD al momento de la contratación.
                  </li>
                  <li>
                    No ser consumidores de ningún tipo de ningún tipo de sustancias estupefacientes o psicotrópicas, salvo prescripción médica.
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <p className="mt-7 ml-0">
                <b>
                CLÁUSULA 8. VIGENCIA DE LA COBERTURA PARA CADA BENEFICIARIO
                </b>
              </p>
              <p className="my-4 ml-0">
                Para cada Beneficiario la cobertura estará vigente por el período correspondiente a la prima pagada              
              </p>
            </div>
            <div>
              <p className="mt-7 ml-0">
                <b>
                CLÁUSULA 9. SUMA ASEGURADA
                </b>
              </p>
              <p className="my-4 ml-0">
                La suma asegurada de la presente póliza es de Cinco Mil Dólares Americanos ($ 5.000) o Diez Mil Dólares 
                Americanos ($ 10.000), (dependiendo del plan contratado) por BENEFICIARIO, por enfermedad o accidente y vigencia anual del contrato.
              </p>
            </div>
            <div>
              <p className="mt-7 ml-0">
                <b>
                CLÁUSULA 10. ÁMBITO TERRITORIAL DE LA COBERTURA
                </b>
              </p>
              <p className="my-4 ml-0">
                La cobertura de esta Póliza se extiende al territorio de la República Bolivariana de Venezuela.              
              </p>
              <div className="mt-10 ">
                <p className="my-3 text-center">
                  <b>Anexo 3.2.1.3 <br/> INVENTARIO DOMICILIARIO</b>
                </p>
                <div className="my-2 ml-0 text-center w-full overflow-x-scroll sm:overflow-x-auto">
                  <table className="border-2 border-gray-800 w-full text-xs md:text-base">
                    <thead className="border-2 border-gray-800 ">
                      <tr>
                        <th className="text-center">PRESENTACIÓN</th>
                        <th className="text-center">TIPO</th>
                        <th className="text-center">PRINCIPIO ACTIVO</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr> <td>TABLETA</td> <td>ANTIHISTAMINICO</td> <td>DESLORATADINA</td> </tr>
                      <tr> <td>TABLETA</td> <td>ANTIHISTAMINICO</td> <td>CETIRIZINA</td> </tr>
                      <tr> <td>TABLETA</td> <td>ANTIHISTAMINICO</td> <td>FEXOFENADINA</td> </tr>
                      <tr> <td>TABLETA</td> <td>ANTI-INFECCIOSO</td> <td>SECNIDAZOL</td> </tr>
                      <tr> <td>TABLETA</td> <td>ANTI-INFECCIOSO</td> <td>NITOZAXONIDA</td> </tr>
                      <tr> <td>TABLETA</td> <td>ANTI-INFECCIOSO</td> <td>METRONIDAZOL</td> </tr>
                      <tr> <td>TABLETA</td> <td>ANTI-INFECCIOSO</td> <td>CEFADROXILO</td> </tr>
                      <tr> <td>TABLETA</td> <td>ANTI-INFECCIOSO</td> <td>LEVOFLOXACINA</td> </tr>
                      <tr> <td>TABLETA</td> <td>ANTI-INFECCIOSO</td> <td>CIPROFLOXACINA</td> </tr>
                      <tr> <td>TABLETA</td> <td>ANTI-INFECCIOSO</td> <td>SULTAMICILINA</td> </tr>
                      <tr> <td>TABLETA</td> <td>ANTI-INFECCIOSO</td> <td>MOXIFLOXACINA</td> </tr>
                      <tr> <td>TABLETA</td> <td>ANTI-INFECCIOSO</td> <td>ACICLOVIR</td> </tr>
                      <tr> <td>TABLETA</td> <td>ANTI-INFECCIOSO</td> <td>FLUCONAZOL</td> </tr>
                      <tr> <td>TABLETA</td> <td>ANTI-INFECCIOSO</td> <td>AZITROMICINA</td> </tr>
                      <tr> <td>TABLETA</td> <td>ANTI-INFECCIOSO</td> <td>AMOXICILINA</td> </tr>
                      <tr> <td>TABLETA</td> <td>ANTI-INFECCIOSO</td> <td>AMOXICILINA + ACIDO CLAVULÁNICO</td> </tr>
                      <tr> <td>TABLETA</td> <td>ANTI-INFECCIOSO</td> <td>MACRODANTINA</td> </tr>
                      <tr> <td>TABLETA</td> <td>ANTI-INFECCIOSO</td> <td>TRIMETOPRIM/SULFA</td> </tr>
                      <tr> <td>TABLETA</td> <td>DIGESTIVO</td> <td>RANITIDINA</td> </tr>
                      <tr> <td>TABLETA</td> <td>DIGESTIVO</td> <td>ESOMEPRAZO</td> </tr>
                      <tr> <td>TABLETA</td> <td>DIGESTIVO</td> <td>OMEPRAZOL</td> </tr>
                      <tr> <td>TABLETA</td> <td>DIGESTIVO</td> <td>PANTOPRAZOL</td> </tr>
                      <tr> <td>TABLETA</td> <td>DIGESTIVO</td> <td>ANTIÁCIDO</td> </tr>
                      <tr> <td>TABLETA</td> <td>DIGESTIVO</td> <td>METOCLOPRAMIDA</td> </tr>
                      <tr> <td>TABLETA</td> <td>DIGESTIVO</td> <td>LOPERAMIDA</td> </tr>
                      <tr> <td>TABLETA</td> <td>ANALG/ANTIINF/ANTIP</td> <td>ACETAMINOFEN</td> </tr>
                      <tr> <td>TABLETA</td> <td>ANALG/ANTIINF/ANTIP</td> <td>IBUPROFENO</td> </tr>
                      <tr> <td>TABLETA</td> <td>ANALG/ANTIINF/ANTIP</td> <td>TIOCOLCHICOSIDO</td> </tr>
                      <tr> <td>TABLETA</td> <td>ANALG/ANTIINF/ANTIP</td> <td>KETOROLAC</td> </tr>
                      <tr> <td>TABLETA</td> <td>ANALG/ANTIINF/ANTIP</td> <td>DICLOFENAC</td> </tr>
                      <tr> <td>TABLETA</td> <td>ANALG/ANTIINF/ANTIP</td> <td>DICLOFENAC POTASICO</td> </tr>
                      <tr> <td>TABLETA</td> <td>ANALG/ANTIINF/ANTIP</td> <td>GENURIN</td> </tr>
                      <tr> <td>TABLETA</td> <td>ANALG/ANTIINF/ANTIP</td> <td>MELOXICAM</td> </tr>
                      <tr> <td>TABLETA</td> <td>ANALG/ANTIINF/ANTIP</td> <td>ACETAMINOFEN/CLORFENIRAMINA</td> </tr>
                      <tr> <td>TABLETA</td> <td>CARDIOVASCULAR</td> <td>CLOPIDOGREL</td> </tr>
                      <tr> <td>TABLETA</td> <td>CARDIOVASCULAR</td> <td>PROPRANOLOL</td> </tr>
                      <tr> <td>TABLETA</td> <td>CARDIOVASCULAR</td> <td>FUROSEMIDA</td> </tr>
                      <tr> <td>TABLETA</td> <td>CARDIOVASCULAR</td> <td>WARFARINA</td> </tr>
                      <tr> <td>TABLETA</td> <td>CARDIOVASCULAR</td> <td>PENTOXIFILINA</td> </tr>
                      <tr> <td>TABLETA</td> <td>CARDIOVASCULAR</td> <td>ASPIRINA</td> </tr>
                      <tr> <td>TABLETA</td> <td>CARDIOVASCULAR</td> <td>DILTIAZEM</td> </tr>
                      <tr> <td>TABLETA</td> <td>VARIOS</td> <td>COMPLEJO B</td> </tr>
                      <tr> <td>TABLETA</td> <td>VARIOS</td> <td>DIFENHIDRAMINA</td> </tr>
                      <tr> <td>TABLETA</td> <td>VARIOS</td> <td>TIAMAZOL 5 MG</td> </tr>
                      <tr> <td>TABLETA</td> <td>VARIOS</td> <td>VITAMINA C</td> </tr>
                      <tr> <td>TABLETA</td> <td>VARIOS</td> <td>BETAHISTINA</td> </tr>
                      <tr> <td>TABLETA</td> <td>VARIOS</td> <td>PREDNISONA</td> </tr>
                      <tr> <td>TABLETA</td> <td>ANALG/ANTIINF/ANTIP</td> <td>ACETAMINOFEN</td> </tr>
                      <tr> <td>TABLETA</td> <td>ANALG/ANTIINF/ANTIP</td> <td>IBUPROFENO</td> </tr>
                      <tr> <td>TABLETA</td> <td>ANALG/ANTIINF/ANTIP</td> <td>DICLOFENAC POTASICO</td> </tr>
                      <tr> <td>TABLETA</td> <td>ANTIHISTAMINICO</td> <td>DESLORATADINA</td> </tr>
                      <tr> <td>TABLETA</td> <td>ANTIHISTAMINICO</td> <td>CETIRIZINA</td> </tr>
                      <tr> <td>TABLETA</td> <td>ANTI-INFECCIOSO</td> <td>CEFADROXILO SUSP.</td> </tr>
                      <tr> <td>TABLETA</td> <td>ANTI-INFECCIOSO</td> <td>AMOXICILINA</td> </tr>
                      <tr> <td>TABLETA</td> <td>ANTI-INFECCIOSO</td> <td>AZITROMICINA</td> </tr>
                      <tr> <td>TABLETA</td> <td>ANTI-INFECCIOSO</td> <td>SULTAMILICINA</td> </tr>
                      <tr> <td>TABLETA</td> <td>ANTI-INFECCIOSO</td> <td>AMOXILICINA + ACIDO CLAVULÁNICO</td> </tr>
                      <tr> <td>TABLETA</td> <td>ANTI-INFECCIOSO</td> <td>CEFTIBUTEN</td> </tr>
                      <tr> <td>TABLETA</td> <td>ANTI-INFECCIOSO</td> <td>METRONIDAZOL</td> </tr>
                      <tr> <td>TABLETA</td> <td>ANTI-INFECCIOSO</td> <td>ALBENDAZOL</td> </tr>
                      <tr> <td>TABLETA</td> <td>VARIOS</td> <td>AMBROXOL</td> </tr>
                      <tr> <td>TABLETA</td> <td>VARIOS</td> <td>METOCLOPRAMIDA</td> </tr>
                      <tr> <td>TABLETA</td> <td>VARIOS</td> <td>SUERO ORAL</td> </tr>
                      <tr> <td>TABLETA</td> <td>VARIOS</td> <td>BETAMETASONA</td> </tr>
                      <tr> <td>TABLETA</td> <td>VARIOS</td> <td>HEPARINA</td> </tr>
                      <tr> <td>TABLETA</td> <td>ANTI-INFECCIOSO</td> <td>BACITRACINA</td> </tr>
                      <tr> <td>TABLETA</td> <td>ANTI-INFECCIOSO</td> <td>MUPIROCINA</td> </tr>
                      <tr> <td>TABLETA</td> <td>ANTI-INFECCIOSO</td> <td>NITROFURAZONA</td> </tr>
                      <tr> <td>TABLETA</td> <td>ANTI-INFECCIOSO</td> <td>ACICLOVIR</td> </tr>
                      <tr> <td>TABLETA</td> <td>ANTI-INFECCIOSO</td> <td>CLORTRIMAZOL</td> </tr>
                      <tr> <td>TABLETA</td> <td>ANTI-INFECCIOSO</td> <td>KETOCONAZOL</td> </tr>
                      <tr> <td>TABLETA</td> <td>ANTI-INFECCIOSO</td> <td>CLOTRIMAZOL/NEOMICINA/DEXAM</td> </tr>
                      <tr> <td>TABLETA</td> <td>VARIOS</td> <td>BETAMETASONA</td> </tr>
                      <tr> <td>TABLETA</td> <td>VARIOS</td> <td>HEPARINA</td> </tr>
                      <tr> <td>TABLETA</td> <td>OTICAS</td> <td>POLIOTICO</td> </tr>
                      <tr> <td>TABLETA</td> <td>OTICAS</td> <td>CIPROFLOXACINA</td> </tr>
                      <tr> <td>TABLETA</td> <td>OTICAS</td> <td>CIPROFLOX + HIDROCORT</td> </tr>
                      <tr> <td>TABLETA</td> <td>OCULARES</td> <td>DEXTRAN</td> </tr>
                      <tr> <td>TABLETA</td> <td>OCULARES</td> <td>DEXAM + TOBRAMICINA</td> </tr>
                      <tr> <td>TABLETA</td> <td>OCULARES</td> <td>DICLOFENAC + TOBRAMICINA</td> </tr>
                      <tr> <td>TABLETA</td> <td>OCULARES</td> <td>COLIRIO</td> </tr>
                      <tr> <td>TABLETA</td> <td>OCULARES</td> <td>PREDNISOLONA</td> </tr>
                      <tr> <td>TABLETA</td> <td>OCULARES</td> <td>ANTIHISTAMÍNICO</td> </tr>
                      <tr> <td>TABLETA</td> <td>OCULARES</td> <td>OLOPATADINA</td> </tr>
                      <tr> <td>TABLETA</td> <td>OCULARES</td> <td>TOBRAMICINA + DEXAM</td> </tr>
                      <tr> <td>TABLETA</td> <td>NASALES</td> <td>RINOMAX</td> </tr>
                      <tr> <td>TABLETA</td> <td>NASALES</td> <td>BUDESONIDA</td> </tr>
                      <tr> <td>TABLETA</td> <td>NASALES</td> <td>OXIMETAZOLINA</td> </tr>
                      <tr> <td>TABLETA</td> <td>NASALES</td> <td>SOLUCIÓN FISIOLÓGICA</td> </tr>
                      <tr> <td>TABLETA</td> <td>INHALADOR</td> <td>IPATROPIO INHALADOR</td> </tr>
                      <tr> <td>TABLETA</td> <td>INHALADOR</td> <td>SALBUTAMOL</td> </tr>
                    </tbody>
                  </table>

                </div>
              </div>

              <div className="mt-10 ">
                <p className="my-3 text-center">
                  <b>Anexo 3.2.2 <br/> Alcance de INVENTARIO CRÍTICO por patologías</b>
                </p>
                <p className="my-4">
                  <b>Patología Cardiovascular: </b> Todas aquellas que afecten el normal funcionamiento del corazón y los vasos sanguíneos (infarto, crisis hipertensiva, insuficiencia cardíaca descompensada).
                </p>
                <p className="my-4">
                  <b>Patología Respiratoria: </b> Todas las Enfermedades o afecciones que alteran el normal funcionamiento del aparato respiratorio y por lo tanto afectan la función respiratoria normal del paciente (crisis de asma, exacerbación de EPOC, insuficiencia respiratoria).
                </p>
                <p className="my-4">
                  <b>Patología Neurológica: </b>  Todas aquellas alteraciones que afecten tanto el sistema nervioso central como el periférico, en especial a nivel cerebral (accidente cerebrovascular, convulsiones, epilepsia, traumatismos de cráneo).
                </p>
                <p className="my-4">
                  <b>Patología Gineco-Obstétrica: </b>as afecciones que alteran el aparato reproductor femenino así como también la atención de la gestante en caso de complicaciones o durante el nacimiento del recién nacido.
                </p>
                <p className="my-4">
                  <b>Patología Urológica: </b>Todas las alteraciones que se presenten en la vía urinaria (desde riñones hasta uretra) tanto del hombre como de la mujer tales como nefrolitiasis, infecciones urinarias, cólicos renales, problemas de próstata y retención aguda de orina.
                </p>
                <p className="my-4">
                  <b>Patología Traumatológica: </b>Todas aquellas alteraciones traumáticas donde exista solución de continuidad de algún hueso, así como las alteraciones en ligamentos, músculos y tendones. En esta clasificación se encuentran las fracturas, esguinces, y luxaciones.
                </p>
                <p className="my-4">
                  <b>Patología Quirúrgica: </b>Todas aquellas afecciones que requieren de solución quirúrgica precoz como apendicitis aguda, colecistitis, así como también procedimientos quirúrgicos programados y ambulatorios.
                </p>
                <p className="my-4">
                  <b>Patología Infecciosa: </b>TTodas las Enfermedades infecciosas (En especial bacterianas) que pueden afectar a la persona. De acuerdo a la localización y sistema afectado puede tratarse de infecciones respiratorias, de vías digestivas, genitourinarias, de piel y partes blandas, abdominales y neurológicas.
                </p>
                <p className="my-4">
                  <b>Patología Dermatológica: </b> Todas las alteraciones del tejido de piel y partes blandas lesionadas por concepto de heridas, quemaduras o infección.
                </p>
              </div>
              <div className="mt-10 ">
                <p className="my-3 text-center">
                  <b>Anexo 3.2.2.3 <br/> INVENTARIO CRÍTICO</b>
                </p>
                <div className="my-2 ml-0 text-center w-full overflow-x-scroll sm:overflow-x-auto">
                  <table className="border-2 border-gray-800 w-full text-xs md:text-base">
                    <thead className="border-2 border-gray-800 ">
                      <tr>
                        <th className="text-center">TIPO</th>
                        <th className="text-center">ITEM</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>KIT DE OBSTETRICIA</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>CATETER HEMODIALISIS</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>VASOS HUMIDIFICADORES DE OXIGENO</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>GASA ESTERIL</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>EQUIPO INFUSION MACROGOTERO</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>EQUIPO INFUSION MICROGOTERO</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>TUBOS EXTENSION K50</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>SCALP 21G Y 23G</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>CATETER YELCO 14G A 24G</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>OBTURADOR</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>LLAVE 3 VIAS</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>MASCARILLA NEBULIZAR ADULTO</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>MASCARILLA NEBULIZAR PEDIATRICA</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>MASCARILLA SIMPLES ADULTO</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>MASCARILLA SIMPLES PEDIATRICA</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>BIGOTERA OXIGENO</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>KIT LAPAROTOMIA</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>KIT PUNCION LUMBAR</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>BATA DE PACIENTE</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>MONO CIRUJANO</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>SABANAS CAMILLA</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>CENTRO DE CAMA</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>CUBREBOTAS</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>GORROS DESCARTABLES</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>MASCARILLA 4 TIRAS</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>ADHESIVO MICROPORE/DURAPORE</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>TEGADERM 10X12</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>AGUJAS 21G</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>INYECTADORA 1CC</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>INYECTADORA 3CC</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>INYECTADORA 5CC</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>INYECTADORA 10CC</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>INYECTADORA 20CC</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>INYECTADORA 60CC</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>GUANTES EXAMEN (CAJA)</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>GUANTES ESTERILES</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>AGUA OXIGENADA 1 LITRO</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>ALCOHOL 1 LITRO</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>YODO SOLUCION (GALON)</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>DESINFECTANTE (GALON)</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>ALGODÓN 500 GR</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>SUTURA NYLON</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>SUTURA CROMICO</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>SUTURA VICRYL</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>COMPRESAS LAPAROTOMÍA</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>CUENTA GOTAS</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>HYPAFIX ROLLO</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>STERI-STRIP</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>HOJILLA BISTURI</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>KIT CESAREA</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>KIT ARTROSCOPIA</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>RESPIRADOR TIPO AMBU ADULTO</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>RESPIRADOR TIPO AMBU PEDIATRICO</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>ELECTRODOS</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>TUBO ENDOTRAQUEAL (DISTINTOS TAMAÑOS)</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>CANULA DE MAYO (DISTINTOS TAMAÑOS)</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>MASCARA LARINGEA (DISTINTOS TAMAÑOS)</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>MASCARA ANESTESIA</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>CANULA DE YANKAUER</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>T DE AYRES</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>SONDA VESICAL LATEX (DISTINTOS TAMAÑOS)</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>SONDA VESICAL SILICONE (DISTINTOS TAMAÑOS)</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>BOLSAS RECOLECTORAS DE ORINA</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>KIT VIA CENTRAL</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>MEDIDOR DE PVC</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>VENDAS ELASTICAS 7.5</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>VENDAS ELASTICAS 15</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>VENDAS TIPO COBAN</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>VENDAS DE YESO</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>WATTA</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>SONDA NASOGASTRICA (DISTINTOS TAMAÑOS)</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>STOKINET</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>CURITAS REDONDAS X100</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>CURITAS ESTÁNDAR</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>KIT DE CONVENIENCIA</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>BAJALENGUA</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>TUBOS LABORATORIO ROJO</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>TUBOS LABORATORIO MORADO</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>TUBOS LABORATORIO AZULES</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>APOSITOS ADHESIVOS 6 X 10 CTM</td> </tr>
                      <tr> <td>MATERIAL MEDICO-QX</td> <td>CATETER ASPIRACION CIRCUITO CERRADO</td> </tr>
                      <tr> <td>SOLUCIONES PARENTERALES</td> <td>SOLUCION 0.9%</td> </tr>
                      <tr> <td>SOLUCIONES PARENTERALES</td> <td>SOLUCION 0.45%</td> </tr>
                      <tr> <td>SOLUCIONES PARENTERALES</td> <td>SOLUCION DEXTTROSA 5%</td> </tr>
                      <tr> <td>SOLUCIONES PARENTERALES</td> <td>SOLUCION RINGER LACTATO</td> </tr>
                      <tr> <td>SOLUCIONES PARENTERALES</td> <td>SOLUCION KCL 7.5%</td> </tr>
                      <tr> <td>SOLUCIONES PARENTERALES</td> <td>BICARBONATO DE SODIO FRASCO</td> </tr>
                      <tr> <td>SOLUCIONES PARENTERALES</td> <td>SULFATO DE MAGNESIO</td> </tr>
                      <tr> <td>MEDICAMENTO</td> <td>RANITIDINA AMP</td> </tr>
                      <tr> <td>MEDICAMENTO</td> <td>KETOPROFENO IM</td> </tr>
                      <tr> <td>MEDICAMENTO</td> <td>DICLOFENAC</td> </tr>
                      <tr> <td>MEDICAMENTO</td> <td>TIOCOLCHICOSIDO</td> </tr>
                      <tr> <td>MEDICAMENTO</td> <td>BROMURO DE HIOSH AMP</td> </tr>
                      <tr> <td>MEDICAMENTO</td> <td>DEXAMETASONA AMP</td> </tr>
                      <tr> <td>MEDICAMENTO</td> <td>METILPREDNISOLINA AMP</td> </tr>
                      <tr> <td>MEDICAMENTO</td> <td>ADRENALINA</td> </tr>
                      <tr> <td>MEDICAMENTO</td> <td>ATROPINA</td> </tr>
                      <tr> <td>MEDICAMENTO</td> <td>CLORFERINAMINA</td> </tr>
                      <tr> <td>MEDICAMENTO</td> <td>FUROSEMIDA</td> </tr>
                      <tr> <td>MEDICAMENTO</td> <td>METOCLOPRAMIDA</td> </tr>
                      <tr> <td>MEDICAMENTO</td> <td>DICLOFENAC</td> </tr>
                      <tr> <td>MEDICAMENTO</td> <td>PROPOFOL AMP</td> </tr>
                      <tr> <td>MEDICAMENTO</td> <td>LIDOCAINA AMP</td> </tr>
                      <tr> <td>MEDICAMENTO</td> <td>OMEPRAZOL/ESOMEPRAZOL AMPOLLA</td> </tr>
                      <tr> <td>MEDICAMENTO</td> <td>OXITOCINA AMPOLLA</td> </tr>
                      <tr> <td>MEDICAMENTO</td> <td>METHERGIN AMPOLLA</td> </tr>
                      <tr> <td>MEDICAMENTO</td> <td>DIAZEPAM AMPOLLA</td> </tr>
                      <tr> <td>MEDICAMENTO</td> <td>FENITOINA AMPOLLA</td> </tr>
                      <tr> <td>MEDICAMENTO</td> <td>AMIKACINA AMP.</td> </tr>
                      <tr> <td>MEDICAMENTO</td> <td>CEFTRIAXONE AMP</td> </tr>
                      <tr> <td>MEDICAMENTO</td> <td>MEROPENEM AMP</td> </tr>
                      <tr> <td>MEDICAMENTO</td> <td>CIPROFLOXAINA AMP</td> </tr>
                      <tr> <td>MEDICAMENTO</td> <td>LEVOFLOXACINA</td> </tr>
                      <tr> <td>MEDICAMENTO</td> <td>MOXIFLOXACINA AMP</td> </tr>
                      <tr> <td>MEDICAMENTO</td> <td>CLINDAMICINA AMP</td> </tr>
                      <tr> <td>MEDICAMENTO</td> <td>VANCOMICINA AMP</td> </tr>
                      <tr> <td>MEDICAMENTO</td> <td>METRONIDAZOL AMP</td> </tr>
                      <tr> <td>MEDICAMENTO</td> <td>AMPICILINA/SULBACTAM AMP</td> </tr>
                      <tr> <td>MEDICAMENTO</td> <td>FLUCONAZOL AMP</td> </tr>
                      <tr> <td>MEDICAMENTO</td> <td>MIDAZOLAM</td> </tr>
                      <tr> <td>MEDICAMENTO</td> <td>ATENOLOL AMP</td> </tr>
                      <tr> <td>MEDICAMENTO</td> <td>DIGOXINA AMP</td> </tr>
                      <tr> <td>MEDICAMENTO</td> <td>DOPAMINA</td> </tr>
                      <tr> <td>MEDICAMENTO</td> <td>NORADRENALINA</td> </tr>
                      <tr> <td>MEDICAMENTO</td> <td>AMIODARONA</td> </tr>
                      <tr> <td>MEDICAMENTO</td> <td>VERAPAMIL</td> </tr>
                      <tr> <td>MEDICAMENTO</td> <td>TRAMADOL O MORFINA</td> </tr>
                      <tr> <td>MEDICAMENTO</td> <td>NIMOTOP</td> </tr>
                      <tr> <td>MEDICAMENTO</td> <td>BUDESONIDA SOLUCION PARA INHALAR</td> </tr>
                      <tr> <td>MEDICAMENTO</td> <td>IPATROPIO SOLUCION PARA INHALAR</td> </tr>
                      <tr> <td>MEDICAMENTO</td> <td>SALBUTAMOL SOLUCIÖN PARA INHALAR</td> </tr>
                      <tr> <td>MEDICAMENTO</td> <td>HEPARINA SODICA</td> </tr>
                      <tr> <td>MEDICAMENTO</td> <td>ASPIRINA TABLETAS</td> </tr>
                      <tr> <td>MEDICAMENTO</td> <td>CLOPIDOGREL TABLETAS</td> </tr>
                      <tr> <td>MEDICAMENTO</td> <td>ENOXAPARINA AMPOLLA 60 MG</td> </tr>
                      <tr> <td>MEDICAMENTO</td> <td>ENOXAPARINA AMPOLLA 40 MG</td> </tr>
                      <tr> <td>MEDICAMENTO</td> <td>CAPTOPRIL TABLETAS</td> </tr>
                      <tr> <td>MEDICAMENTO</td> <td>CLONIDINA AMPOLLA</td> </tr>
                      <tr> <td>MEDICAMENTO</td> <td>NITROPRUSIATO AMPOLLA</td> </tr>
                      <tr> <td>MEDICAMENTO</td> <td>NITROGLICERINA AMPOLLA</td> </tr>
                      <tr> <td>MEDICAMENTO</td> <td>INSULINA CRISTALINA FRASCO</td> </tr>
                      <tr> <td>MEDICAMENTO</td> <td>INSULINA NPH FRASCO</td> </tr>
                    </tbody>
                  </table>

                </div>
              </div>
              <div className="mt-10 ">
                <p className="mt-5 my-3 text-right font-bold">
                Última actualización: 22 de marzo 2022
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

TermsConditionPrivate.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withMobileDialog({ breakpoint: "xs" })(
  withStyles(styles)(TermsConditionPrivate)
);
